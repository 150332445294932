import axios from './appAxios'
import md5 from 'js-md5'

export default {
    loginApi({params, callback}) {
        // {"emailAddress":"18665339232@163.com","password":"25d55ad283aa400af464c76d713c07ad"}
        params.pwd = md5(params.pwd)
        axios.post(`/app/user/login`, params).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    loginByThirdApi({params, callback}) {
        axios.post(`/app/user/signInByThird`, params).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    deleteApi({params, callback}) {
        // params.password = md5(params.password)
        axios.delete(`/app/user/deleteUserByApp/${params.id}?webLogin=true`).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    }
}