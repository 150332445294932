import React from 'react'
import {Component} from 'react'
import {withRouter, HashRouter as Router, Link} from 'react-router-dom'
import {message} from 'antd'
import http from '../../api/request'
import '../../assets/css/index.scss'

class Nav extends Component {
    constructor() {
        super()
    }

    componentDidMount() {
        if (this.props.location.pathname === '/' && !sessionStorage.getItem('token')) {
            this.props.parentProps.history.push('/login')
        } else if (this.props.location.pathname === '/' && sessionStorage.getItem('token')) {
            this.props.parentProps.history.push('/user')
        }
    }

    refresh = (path) => {
        const curPath = this.props.location.pathname
        if (path === curPath) {
            this.props.history.go(0)
            window.scrollTo(0, 0)
        }
    }

    toHome = () => {
        const curPath = this.props.location.pathname
        if (curPath === '/user') {
            this.props.history.go(0)
            window.scrollTo(0, 0)
        } else {
            this.props.history.push('/user')
        }
    }

    out = () => {
        http.logout({
            callback: this.outCallback
        })
    }

    outCallback = (res) => {
        if (res.data.code === 0) {
            this.props.parentProps.history.push('/login')
            sessionStorage.clear()
        } else {
            message.warning(res.data.message)
        }
    }

    render() {
        return(
            <div className="nav">
                <div className="con clear">
                    <div className="logo" onClick={this.toHome}></div>
                    <ul className="clear">
                        <li onClick={this.refresh.bind(this, '/user')} className={(this.props.location.pathname.split('/')[1].slice(0, 3) === 'use'|| this.props.location.pathname.split('/')[1].slice(0, 8) === 'activate') ? 'current' : ''}><Link to="/user">USER MANAGEMENT</Link></li>
                        {/*<li onClick={this.refresh.bind(this, '/reward')} className={this.props.location.pathname.split('/')[1].slice(0, 3) === 'rew' ? 'current' : ''}><Link to="/reward">REWARD</Link></li>
                        <li onClick={this.refresh.bind(this, '/record')} className={this.props.location.pathname.split('/')[1].slice(0, 3) === 'rec' ? 'current' : ''}><Link to="/record">REDEEM RECORDS</Link></li>*/}
                        <li onClick={this.refresh.bind(this, '/promo')} className={(this.props.location.pathname.split('/')[1].slice(0, 3) === 'pro' ||
                        this.props.location.pathname.split('/')[1] === 'basic') ? 'current' : ''}><Link to="/promo">PROMO CODE</Link></li>
                        <li onClick={this.refresh.bind(this, '/version')} className={this.props.location.pathname.split('/')[1].slice(0, 3) === 'ver' ? 'current' : ''}><Link to="/version">VERSION DETAILS</Link></li>
                    </ul>
                    <div className="out" onClick={this.out}>Log Out</div>
                </div>
            </div>
        )
    }
}

export default withRouter(Nav)