import React from 'react'
import {Component} from 'react'
import {DatePicker, Input, Table, Space, Pagination} from 'antd'
import { SearchOutlined, MinusCircleOutlined, CheckCircleOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import http from '../../api/request'
import format from '../../untils/format'
import { message, Modal } from 'antd'
const { RangePicker } = DatePicker
const { Column } = Table;

function disabledDate(current) {
    // Can not select days before today and today
    return current && current > new Date(new Date().toLocaleDateString()).getTime() + 86400000
}

class User extends Component {
    constructor() {
        super()
        this.state = {
            start: 0,
            end: 0,
            keyWord: null,
            timer: null,
            page: 1,
            loading: true,
            list: [],
            totalRecord: 0,
            modal2Visible: false,
            id: null,
            status: null,
            index: null,
            modalVisible: false
        }
    }
    // 开始请求
    componentDidMount () {
        http.userList({
            params: {
                page: this.state.page,
                size: 10,
                data: {
                    endTime: 0 + 86399,
                    startTime: 0
                }
            },
            callback: this.callback
        })
        window.scrollTo(0, 0)
    }

    // 请求回调
    callback = (res) => {
        if (res.data.code === 0) {
            res.data.data.list.forEach(item => {
                item.registeredDate = format.formatTime(item.registeredDate)
                item.key = item.id
                if (item.subscription === 1)
                    item.subscription = 'Premium'
                else if (item.subscription === 0)
                    item.subscription = 'Free'
                if (item.birthday) {
                    if (item.birthday.indexOf('-') !== -1) {
                        let temp = item.birthday.split('-')
                        item.birthday = `${temp[2]}/${temp[1]}/${temp[0]}`
                    }
                } else{
                    item.birthday = '-'
                }
                item.area = `${item.country == null ? '-' : item.country}, ${item.city == null ? '-' : item.city}`
            })
            this.setState({
                list: res.data.data.list,
                loading: false,
                totalRecord: res.data.data.totalRecord
            })
        } else if (res.data.code === 10001) {
            //message.warning(res.data.message)
            this.props.history.push('/login')
        } else if (res.data.code === 10002) {
            message.warning(res.data.message)
            this.props.history.push('/login')
        } else {
            message.warning(res.data.message)
        }
    }

    // 选中了时间的事件
    dateEvt = (value, string) => {
        let start
        let end
        if (!value) {
            start = 0
            end = 0
        } else {
            start = (new Date(string[0])).getTime() / 1000
            end = (new Date(string[1])).getTime() / 1000
        }
        this.setState({
            start: start,
            end: end
        }, () => {
            this.setState({
                list: [],
                loading: true
            }, () => {
                http.userList({
                    params: {
                        page: this.state.page,
                        size: 10,
                        data: {
                            startTime: start,
                            endTime: end + 86399,
                            content: this.state.keyWord
                        }
                    },
                    callback: this.callback
                })
            })
        })
    }

    // 输入框的事件
    keyEvt = (e) => {
        this.setState({
            keyWord: e.target.value
        }, () => {
            if (this.state.timer) {
                clearTimeout(this.state.timer)
            }
            const temp = setTimeout(() => {
                this.search(this.state.keyWord)
            }, 2000)
            this.setState({
                timer: temp
            })
        })
    }

    // 防抖查询
    search = (word) => {
        this.setState({
            list: [],
            page: 1,
            loading: true
        }, () => {
            http.userList({
                params: {
                    page: 1,
                    size: 10,
                    data: {
                        content: word,
                        startTime: this.state.start,
                        endTime: this.state.end + 86399
                    }
                },
                callback: this.callback
            })
        })
    }

    // 切换页码
    pageEvt = (page, pageSize) => {
        if (page === this.state.page)
            return
        this.setState({
            page: page,
            list: [],
            loading: true
        }, () => {
            window.scrollTo(0, 0)
            http.userList({
                params: {
                    page: page,
                    size: 10,
                    data: {
                        startTime: this.state.start,
                        endTime: this.state.end + 86399,
                        content: this.state.keyWord
                    }
                },
                callback: this.callback
            })
        })
    }

    // 用户操作
    operate = (status, id, index) => {
        if (status === 1) {
            this.setState({
                modal2Visible: true,
                id: id,
                status: status,
                index: index
            })
        } else if (status === 0) {
            this.setState({
                loading: true
            })
            http.update({
                params: {
                    index: index,
                    data: {
                        id: id,
                        status: status === 1 ? 0 : 1
                    }
                },
                callback: this.operateCallback
            })
        } else if (status === 'delete') {
            this.setState({
                modalVisible: true,
                id: id
            })
        }
    }

    // 弹窗操作
    setModal2Visible(modal2Visible, status, id, index) {
        if (status) {
            this.setState({
                loading: true,
                modal2Visible:false
            })
            http.update({
                params: {
                    index: index,
                    data: {
                        id: id,
                        status: status === 1 ? 0 : 1
                    }
                },
                callback: this.operateCallback
            })
            // this.setState({ modal2Visible });
        } else {
            this.setState({ modal2Visible });
        }
    }

    setModalVisible(modalVisible, text, id) {
        if (text === 'ok') {
            this.setState({
                loading: true,
                modalVisible:false
            })
            http.delUser({
                params: id,
                callback: this.deleteCallback
            })
            // this.setState({ modal2Visible });
        } else {
            this.setState({ modalVisible });
        }
    }

    // 操作回调
    operateCallback = (res, index) => {
        this.setState({
            loading: false
        })
        if (res.data.code === 0) {
            let temp = this.state.list
            temp[index].status = temp[index].status === 1 ? 0 : 1
            this.setState({
                list: temp
            })
        } else if (res.data.code === 10001) {
            //message.warning(res.data.message)
            this.props.history.push('/login')
        } else if (res.data.code === 10002) {
            message.warning(res.data.message)
            this.props.history.push('/login')
        } else {
            message.warning(res.data.message)
        }
    }

    deleteCallback = (res) => {
        this.setState({
            loading: false
        })
        if (res.data.code === 0) {
            this.setState({
                list: [],
                loading: true
            }, () => {
                http.userList({
                    params: {
                        page: this.state.page,
                        size: 10,
                        data: {
                            startTime: this.state.start,
                            endTime: this.state.end + 86399,
                            content: this.state.keyWord
                        }
                    },
                    callback: this.callback
                })
            })
        } else if (res.data.code === 10001) {
            //message.warning(res.data.message)
            this.props.history.push('/login')
        } else if (res.data.code === 10002) {
            message.warning(res.data.message)
            this.props.history.push('/login')
        } else {
            message.warning(res.data.message)
        }
    }

    jump = () => {
        this.props.history.push(`/activate`)
    }

    render() {
        const data = this.state.list

        const loading = this.state.loading

        const total = this.state.totalRecord

        const table = <Table dataSource={data} pagination={false}
                    onRow = {record => {
                        return {
                            onClick: event => {
                                // // console.log(event)
                            }
                        }
                    }}
                    loading={loading}
                    >
                        <Column title="Name" dataIndex="name" key="id"
                        render={(text, record) => (
                            <div className={record.status === 1 ? 'green-dot' : 'red-dot'}>{record.name}
                            <span className={record.userSex === 0 ? 'sex1' : (record.userSex === 1 ? 'sex2' : (record.userSex === 2 ? 'sex3' : ''))}></span></div>
                        )} />
                        <Column title="Email Address" dataIndex="email" key="id" />
                        <Column title="Subscription" dataIndex="subscription" key="id" />
                        <Column title="Current Points" dataIndex="points" key="id" />
                        <Column title="Location" dataIndex="area" key="id" />
                        <Column title="Year of birth" dataIndex="birthday" key="id" />
                        <Column title="Registered Date" dataIndex="registeredDate" key="id" />
                        <Column
                        title="Action"
                        key="id"
                        width={230}
                        render={(text, record, index) => (
                            <Space size="middle">
                            <button className={record.status === 1 ? 'btn dis' : 'none'} 
                            onClick={this.operate.bind(this, record.status, record.id, index)}>
                                <MinusCircleOutlined />Disable</button>
                            <button className={record.status !== 1 ? 'btn ena' : 'none'} 
                            onClick={this.operate.bind(this, record.status, record.id, index)}>
                                <CheckCircleOutlined />Enable</button>
                            <button className="btn del"
                            onClick={this.operate.bind(this, 'delete', record.id)}><DeleteOutlined />Delete</button>
                            </Space>
                        )}
                        />
                    </Table>
        

        return(
            <div className="main">
                <div className="search clear">
                    <div className="date">
                        <RangePicker disabledDate={disabledDate} format="YYYY/MM/DD" 
                        onChange={this.dateEvt.bind(this)} />
                    </div>
                    <div className="create" style={{'float':'right'}}><button onClick={this.jump}><PlusOutlined />Activate Premium</button></div>
                    <div className="input">
                        <i><SearchOutlined /></i>
                        <Input placeholder="Search" value={this.state.keyWord} onChange={this.keyEvt.bind(this)} />
                    </div>
                </div>
                <div className="list">{table}</div>
                <div className="page">
                    {this.state.totalRecord === 0 ? '' : <Pagination showQuickJumper total={total} 
                    defaultPageSize={10} 
                    showSizeChanger={false}
                    onChange={this.pageEvt.bind(this)} />}
                </div>

                <Modal
                title=""
                centered
                visible={this.state.modal2Visible}
                onOk={() => this.setModal2Visible(false, this.state.status, this.state.id, this.state.index)}
                onCancel={() => this.setModal2Visible(false)}
                closable={false}
                maskClosable={false}
                >
                <p className="text">The promo code will become invalid immediately after disable, Are you sure you want to disable?</p>
                </Modal>

                <Modal
                title=""
                centered
                visible={this.state.modalVisible}
                onOk={() => this.setModalVisible(false, 'ok', this.state.id)}
                onCancel={() => this.setModalVisible(false, 'cancel')}
                closable={false}
                maskClosable={false}
                >
                <p className="text">All information will be deleted after deletion. Are you sure you want to delete?</p>
                </Modal>

                
            </div>
        )
    }
}

export default User