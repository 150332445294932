import React from 'react'
import { Component } from 'react'
import http from '../../api/request'
import { ArrowLeftOutlined, LoadingOutlined, RetweetOutlined } from '@ant-design/icons'
import { message, Form, Input, Button, Spin, Radio } from 'antd'

class Basic extends Component {
    constructor() {
        super()
        this.state = {
            requesting: false,
            warn: false
        }
    }
    formRef = React.createRef();

    componentDidMount () {
        window.scrollTo(0, 0)
    }

    onFinish = (values) => {
        if (this.state.warn === true)
            return
        let data = {
            amountofUsers: Number(values.user),
            email: values.email
        }
        this.setState({
            requesting: true
        })
        let temp = setTimeout(() => {
            if (this.state.requesting === true) {
                this.setState({
                    requesting: false
                })
            }
        }, 60000)
        http.setBasicQuotaReminder({
            params: data,
            callback: (res) => {
                clearTimeout(temp)
                this.setState({
                    requesting: false
                })
                if (res.data.code === 0) {
                    this.props.history.replace('/promo')
                } else {
                    message.warning(res.data.message)
                }
            }
        })
    }

    onFinishFailed = (errorInfo) => {
        // console.log('Failed:', errorInfo);
    }

    // 请求回调
    callback = (res) => {
        this.setState({
            requesting: false
        })
        if (res.data.code === 0) {
            this.props.history.go(-1)
        } else {
            message.warning(res.data.message)
        }
    }

    goback = () => {
        this.props.history.go(-1)
    }

    handleChange = (e) => {
        let temp = e.target.value
        var reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
        this.formRef.current.setFieldsValue({
            email: temp
        })
        if(reg.test(temp)) {
            this.setState({
                warn: false
            })
        } else{
            this.setState({
                warn: true
            })
        }
        
    }

    extraEvt = (e) => {
        this.formRef.current.setFieldsValue({
            user: e.target.value.replace(/[^\d]/g,'')
        })
    }


    render() {
        const layout = {
            labelCol: {
              span: 6,
            },
            wrapperCol: {
              span: 14,
            },
        }
        const tailLayout = {
            wrapperCol: {
              offset: 6,
              span: 14,
            },
        }

        return (
            <div className="main">
                <div className="back"><span onClick={this.goback}><ArrowLeftOutlined /> Back</span></div>
                <div className="box">
                    <h3>Set Basic Quota Reminder</h3>
                    <h4>When the total number of users reaches the set number, a reminder will be sent to the mailbox</h4>
                    <Form
                        {...layout}
                        name="basic"
                        ref={this.formRef}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        >
                        <Form.Item
                            label="Receive Email"
                            name="email"
                            rules={[
                            {
                                required: true,
                                message: ''
                            },
                            ]}
                        >
                            <Input className={this.state.warn ? 'warn-promo' : ''} placeholder="" onBlur={this.handleChange.bind(this)} />
                        </Form.Item>

                        <Form.Item
                            label="Amount of Users"
                            name="user"
                            rules={[
                            {
                                required: true,
                                message: ''
                            },
                            ]}
                        >
                            <Input placeholder="" onKeyUp={this.extraEvt.bind(this)} maxLength={30} />
                        </Form.Item>


                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                            Create
                            </Button>
                            <Button style={{'marginLeft': '20px'}} onClick={this.goback}>
                            Cancel
                            </Button>
                        </Form.Item>
                    </Form>
                </div>

                {this.state.requesting ? <div className="loadmask"><Spin tip=""></Spin></div> : ''}
            </div>
        )
    }
}

export default Basic