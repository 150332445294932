import React from 'react'
import { Component } from 'react'
import http from '../../api/request'
import { ArrowLeftOutlined, LoadingOutlined, RetweetOutlined } from '@ant-design/icons'
import { message, Form, Input, Button, Spin, Radio } from 'antd'

class Promoadd extends Component {
    constructor() {
        super()
        this.state = {
            requesting: false,
            loading: false,
            radioMaxInput: '',
            radioCodeInput: '',
            radioMaxInputWarn: false,
            radioCodeInputWarn: false
        }
    }
    formRef = React.createRef();

    componentDidMount () {
        window.scrollTo(0, 0)
    }

    onFinish = (values) => {
        if (values.max === 2 && !this.state.radioMaxInput) {
            this.setState({
                radioMaxInputWarn: true
            })
        }
        if (values.date === 2 && !this.state.radioCodeInput) {
            this.setState({
                radioCodeInputWarn: true
            })
        }
        if (values.code.length < 4) {
            message.warning('Please enter 4-12 digits or letters')
        }
        if ((values.max === 2 && !this.state.radioMaxInput) || (values.date === 2 && !this.state.radioCodeInput) || values.code.length < 4)
            return
        let data = {
            codeExpirationDate: values.date === 1 ? 0 : Number(this.state.radioCodeInput),
            extraFreeDuration: Number(values.extra),
            influencersName: values.influencerName,
            maximumNumberUser: values.max === 1 ? 0 : Number(this.state.radioMaxInput),
            promoCode: values.code
        }
        this.setState({
            requesting: true
        })
        http.promotAdd({
            params: data,
            callback: this.callback
        })
        setTimeout(() => {
            if (this.state.requesting === true) {
                this.setState({
                    requesting: false
                })
            }
        }, 60000)
    }

    // 请求回调
    callback = (res) => {
        this.setState({
            requesting: false
        })
        if (res.data.code === 0) {
            localStorage.setItem('type',this.props.match.params.id)
            this.props.history.go(-1)
        } else {
            message.warning(res.data.message)
        }
    }

    onFinishFailed = (errorInfo) => {
        // console.log('Failed:', errorInfo);
        if (errorInfo.values.max === 2 && !this.state.radioMaxInput) {
            this.setState({
                radioMaxInputWarn: true
            })
        }
        if (errorInfo.values.date === 2 && !this.state.radioCodeInput) {
            this.setState({
                radioCodeInputWarn: true
            })
        }
        if ((errorInfo.values.max === 2 && !this.state.radioMaxInput) || (errorInfo.values.date === 2 && !this.state.radioCodeInput))
            return
    }


    // 上传回调
    uploadCallback = (res) => {
        this.setState({
            loading: false
        })
        if (res.data.code === 0) {
            this.setState({
                tempImg: res.data.data
            })
        } else if (res.data.code === 10001) {
            //message.warning(res.data.message)
            this.props.history.push('/login')
        } else if (res.data.code === 10002) {
            message.warning(res.data.message)
            this.props.history.push('/login')
        } else {
            message.warning(res.data.message)
        }
    }

    goback = () => {
        this.props.history.go(-1)
    }

    handleChange = (key, e) => {
        if (key === 'radioMaxInput') {
            let temp = e.target.value
            temp = temp.replace(/\D/g, '')
            this.setState({
                radioMaxInput: temp,
                radioMaxInputWarn: false
            })
        } else if (key === 'radioCodeInput') {
            let temp = e.target.value
            temp = temp.replace(/\D/g, '')
            this.setState({
                radioCodeInput: temp,
                radioCodeInputWarn: false
            })
        }
    }

    randomWord = (randomFlag, min, max) => {
        var str = "",
        range = min,
        arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
        // 随机产生
        if(randomFlag){
            range = Math.round(Math.random() * (max-min)) + min;
        }
        for(var i=0; i<range; i++){
            let pos = Math.round(Math.random() * (arr.length-1));
            str += arr[pos];
        }
        return str;
    }

    getString = () => {
        let temp = this.randomWord(false, 12)
        this.formRef.current.setFieldsValue({
            code: temp
        })
    }

    extraEvt = (e) => {
        this.formRef.current.setFieldsValue({
            extra: e.target.value.replace(/[^\d]/g,'')
        })
    }

    render() {
        const layout = {
            labelCol: {
              span: 6,
            },
            wrapperCol: {
              span: 14,
            },
        }
        const tailLayout = {
            wrapperCol: {
              offset: 6,
              span: 14,
            },
        }

        let loading = ''
        if (this.state.loading) {
            loading = <div className="loading"><LoadingOutlined /></div>
        }

        let radioMaxInput = this.state.radioMaxInput
        let radioCodeInput = this.state.radioCodeInput
        let radioMaxInputWarn = this.state.radioMaxInputWarn
        let radioCodeInputWarn = this.state.radioCodeInputWarn

        return (
            <div className="main">
                <div className="back"><span onClick={this.goback}><ArrowLeftOutlined /> Back</span></div>
                <div className="box">
                    <h1>Create Promo Code</h1>
                    <Form
                        {...layout}
                        name="basic"
                        ref={this.formRef}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        >
                        <Form.Item
                            label="Influencers Name"
                            name="influencerName"
                            rules={[
                            {
                                required: true,
                                message: ''
                            },
                            ]}
                        >
                            <Input placeholder="" maxLength={30} />
                        </Form.Item>

                        <Form.Item
                            label="Promo Code"
                            name="code"
                            rules={[
                            {
                                required: true,
                                message: ''
                            },
                            ]}
                        >
                            <Input placeholder="Please enter 4-12 digits or letters" maxLength={12} suffix={<div className="random" onClick={this.getString.bind(this)}><RetweetOutlined />Random</div>} />
                        </Form.Item>

                        <Form.Item
                            label="Extra Free Duration"
                            name="extra"
                            rules={[
                            {
                                required: true,
                                message: '',
                                pattern: /^[1-9]\d*$/
                            },
                            ]}
                        >
                            <Input maxLength={30} onKeyUp={this.extraEvt.bind(this)} suffix={<div>days</div>} />
                        </Form.Item>

                        <Form.Item
                            label="Maximum Number of uses"
                            name="max"
                            initialValue={1}
                            rules={[
                            {
                                required: true,
                                message: ''
                            },
                            ]}
                        >
                            <Radio.Group>
                                <div className="radioLines"><Radio value={1}>Unlimited</Radio></div>
                                <div><Radio value={2}><Input className={radioMaxInputWarn === true ? 'warn-promo' : ''}
                                value={radioMaxInput} onChange={this.handleChange.bind(this, 'radioMaxInput')} maxLength={10} /></Radio>
                                <div className="tag">days</div></div>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            label="Code Expiration Date"
                            name="date"
                            initialValue={1}
                            rules={[
                            {
                                required: true,
                                message: ''
                            },
                            ]}
                        >
                            <Radio.Group>
                                <div className="radioLines">
                                    <Radio value={1}>Permanently Available</Radio></div>
                                <div className="radioInput">
                                    <Radio value={2}>
                                        <Input className={radioCodeInputWarn ? 'warn-promo' : ''}
                                        value={radioCodeInput} onChange={this.handleChange.bind(this, 'radioCodeInput')}
                                        maxLength={10} suffix={<div>days</div>} />
                                    </Radio>
                                </div>
                            </Radio.Group>
                        </Form.Item>


                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                            Create
                            </Button>
                            <Button style={{'marginLeft': '20px'}} onClick={this.goback}>
                            Cancel
                            </Button>
                        </Form.Item>
                    </Form>
                </div>

                {this.state.requesting ? <div className="loadmask"><Spin tip=""></Spin></div> : ''}
            </div>
        )
    }
}

export default Promoadd
