import React from 'react'
import { message, Table, Pagination, Space, Modal } from 'antd'
import { SettingOutlined ,PlusOutlined, ArrowUpOutlined, MinusCircleOutlined, CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import http from '../../api/request'
import axios from 'axios'
const CancelToken = axios.CancelToken;
var source = CancelToken.source();
const { Column } = Table;

class Promo extends React.Component {
    constructor() {
        super()
        this.state = {
            loading: true,
            page: 1,
            cur: 2,
            list: [],
            totalRecord: 0,

            modal2Visible: false,
            id: null,
            status: null,
            index: null,
            modalVisible: false
        }
    }

    // 开始请求
    componentDidMount () {
        this.getList()
    }

    getList = () => {
        http.promotList({
            params: {
                page: this.state.page,
                size: 10
            },
            callback: this.callback,
            cancelToken: source.token
        })
        window.scrollTo(0, 0)
    }

    // 请求回调
    callback = (res) => {
        if (res.data.code === 0) {
            res.data.data.list.forEach(item => {
                item.key = item.id
            })
            this.setState({
                list: res.data.data.list,
                loading: false,
                totalRecord: res.data.data.totalRecord
            })
        } else if (res.data.code === 10001) {
            //message.warning(res.data.message)
            this.props.history.push('/login')
        } else if (res.data.code === 10002) {
            message.warning(res.data.message)
            this.props.history.push('/login')
        } else {
            message.warning(res.data.message)
        }
    }


    // 切换页码
    pageEvt = (page, pageSize) => {
        if (this.state.loading) {
            source.cancel('Operationcanceled');
            source = null
            source = CancelToken.source();
        }
        if (page === this.state.page)
            return
        this.setState({
            page: page,
            list: [],
            loading: true
        }, () => {
            window.scrollTo(0, 0)
            this.getList()
        })
    }

    // 去新增页
    jump = () => {
        this.props.history.push(`/promoadd`)
    }
    jump2 = (path) => {
        this.props.history.push('/basic')
    }


    // 用户操作
    operate = (status, id, index) => {
        if (status === 1) {
            this.setState({
                modal2Visible: true,
                id: id,
                status: status,
                index: index
            })
        } else if (status === 0) {
            this.setState({
                loading: true
            })
            http.updatePromoteCode({
                params: {
                    index: index,
                    data: {
                        id: id,
                        status: status === 1 ? 0 : 1
                    }
                },
                callback: this.operateCallback
            })
        } else if (status === 'delete') {
            this.setState({
                modalVisible: true,
                id: id
            })
        }
    }

    // 弹窗操作
    setModal2Visible(modal2Visible, status, id, index) {
        if (status) {
            this.setState({
                loading: true,
                modal2Visible:false
            })
            http.updatePromoteCode({
                params: {
                    index: index,
                    data: {
                        id: id,
                        status: status === 1 ? 0 : 1
                    }
                },
                callback: this.operateCallback
            })
            // this.setState({ modal2Visible });
        } else {
            this.setState({ modal2Visible });
        }
    }

    setModalVisible(modalVisible, text, id) {
        if (text === 'ok') {
            this.setState({
                loading: true,
                modalVisible:false
            })
            http.delPromot({
                params: id,
                callback: this.deleteCallback
            })
            // this.setState({ modal2Visible });
        } else {
            this.setState({ modalVisible });
        }
    }

    // 操作回调
    operateCallback = (res, index) => {
        this.setState({
            loading: false
        })
        if (res.data.code === 0) {
            let temp = this.state.list
            temp[index].status = temp[index].status === 1 ? 0 : 1
            this.setState({
                list: temp
            })
        } else if (res.data.code === 10001) {
            //message.warning(res.data.message)
            this.props.history.push('/login')
        } else if (res.data.code === 10002) {
            message.warning(res.data.message)
            this.props.history.push('/login')
        } else {
            message.warning(res.data.message)
        }
    }

    deleteCallback = (res) => {
        this.setState({
            loading: false
        })
        if (res.data.code === 0) {
            this.setState({
                list: [],
                loading: true
            }, () => {
                this.getList()
            })
        } else if (res.data.code === 10001) {
            //message.warning(res.data.message)
            this.props.history.push('/login')
        } else if (res.data.code === 10002) {
            message.warning(res.data.message)
            this.props.history.push('/login')
        } else {
            message.warning(res.data.message)
        }
    }

    exportExcel = (id) => {
        window.open(`https://api.safie.com.au/oa/promoteCode/downloadsPromoteCodeExcel/${id}`, '_blank')

    }

    downloadCallback = (res) => {
        if (res.data.code === 0) {
            // 
        } else if (res.data.code === 10001) {
            //message.warning(res.data.message)
            this.props.history.push('/login')
        } else if (res.data.code === 10002) {
            message.warning(res.data.message)
            this.props.history.push('/login')
        } else {
            message.warning(res.data.message)
        }
    }

    exportApi = (data, fileName) => {
        let blob = new Blob([data], {
            //type类型后端返回来的数据中会有，根据自己实际进行修改
            // 表格下载为 application/xlsx，压缩包为 application/zip等，

            type: "application/xlsx"
        });
        let filename = fileName;
        if (typeof window.navigator.msSaveBlob !== "undefined") {
            window.navigator.msSaveBlob(blob, filename);
        } else {
            var blobURL = window.URL.createObjectURL(blob);
            // 创建隐藏<a>标签进行下载
            var tempLink = document.createElement("a");
            tempLink.style.display = "none";
            tempLink.href = blobURL;
            tempLink.setAttribute("download", `Activate Premium`);
            if (typeof tempLink.download === "undefined") {
            tempLink.setAttribute("target", "_blank");
            }
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobURL);
        }
    }

    render() {
        const data = this.state.list

        const loading = this.state.loading

        let table = <Table dataSource={data} pagination={false}
                    onRow = {record => {
                        return {
                            onClick: event => {
                                // // console.log(event)
                            }
                        } 
                    }}
                    loading={loading}
                    >
                        <Column title="Promo Code" dataIndex="promoCode" key="id" />
                        <Column title="Influencers Name" dataIndex="influencersName" key="id" />
                        <Column title="Extra Free Duration" dataIndex="extraFreeDuration" key="id"
                        render={(text, record, index) => (
                            <div>{text} {text > 1 ? 'days' : 'day'}</div>
                        )} />
                        <Column title="Usage Amount" dataIndex="usageAmount" key="id" />
                        <Column title="Code Expiration Date" dataIndex="codeExpirationDate" key="id" />
                        <Column
                        title="Functions"
                        key="id"
                        width={330}
                        render={(text, record, index) => (
                            <Space size="middle">
                            <button className="btn ena" onClick={this.exportExcel.bind(this, record.id)}>
                            <ArrowUpOutlined />Export</button>

                            <button className={record.status === 1 ? 'btn dis' : 'none'} 
                            onClick={this.operate.bind(this, record.status, record.id, index)}>
                            <MinusCircleOutlined />Disable</button>

                            <button className={record.status !== 1 ? 'btn ena' : 'none'} 
                            onClick={this.operate.bind(this, record.status, record.id, index)}>
                            <CheckCircleOutlined />Enable</button>

                            <button className="btn del"
                            onClick={this.operate.bind(this, 'delete', record.id)}>
                            <DeleteOutlined />Delete</button>
                            </Space>
                        )}
                        />
                    </Table>

        return (
            <div className="main">
                <div className="tab clear">
                    <div className="create" style={{'float':'right'}}><button onClick={this.jump}><PlusOutlined />Create Promo Code</button></div>
                    <div className="create" style={{'float':'right', 'marginRight': '30px'}}><button onClick={this.jump2}><SettingOutlined />Basic Quota Reminder</button></div>
                </div>
                <div className="list">{table}</div>
                <div className="page">
                    {this.state.totalRecord === 0 ? '' : <Pagination showQuickJumper total={this.state.totalRecord} 
                    defaultPageSize={10} 
                    showSizeChanger={false}
                    onChange={this.pageEvt.bind(this)} />}
                </div>

                <Modal
                title=""
                centered
                visible={this.state.modal2Visible}
                onOk={() => this.setModal2Visible(false, this.state.status, this.state.id, this.state.index)}
                onCancel={() => this.setModal2Visible(false)}
                closable={false}
                maskClosable={false}
                >
                <p className="text">Promo code will become invalid immediately after disabling it. Are you sure you want to continue?</p>
                </Modal>

                <Modal
                title=""
                centered
                visible={this.state.modalVisible}
                onOk={() => this.setModalVisible(false, 'ok', this.state.id)}
                onCancel={() => this.setModalVisible(false, 'cancel')}
                closable={false}
                maskClosable={false}
                >
                <p className="text">The promo code will become invalid immediately after deletion, Are you sure you want to delete?</p>
                </Modal>
            </div>
        )
    }
}

export default Promo
