import React from 'react'
import {Component} from 'react'
import { message, Spin } from 'antd'
import api from '../api/request'
import '../assets/css/login.scss'

class Login extends Component {
    constructor() {
        super()
        this.state = {
            username: '',
            usernameWarn: false,
            password: '',
            passwordWarn: false,
            loading: false,
            timer: null
        }
    }

    handleChange = (key, e) => {
        if (key === 'username') {
            let temp = e.target.value
            temp = temp.replace(/[\W]/g,'')
            this.setState({
                username: temp
            }, () => {
                if (this.state.username.replace(/\s+/g,'') !== '') {
                    this.setState({
                        usernameWarn: false
                    })
                }
            })
        } else if (key === 'password') {
            this.setState({
                password: e.target.value
            }, () => {
                if (this.state.password.replace(/\s+/g,'') !== '') {
                    this.setState({
                        passwordWarn: false
                    })
                }
            })
        }
    }

    sign = () => {
        if (this.state.username.replace(/\s+/g,'') === '') {
            this.setState({
                usernameWarn: true
            })
        }
        if (this.state.password.replace(/\s+/g,'') === '') {
            this.setState({
                passwordWarn: true
            })
        }
        if (this.state.username.replace(/\s+/g,'') === '' || this.state.password.replace(/\s+/g,'') === '') {
            return
        }

        this.setState({
            loading: true,
            timer: setInterval(this.resetDefault, 60000)
        })

        api.signIn({
            params: {
                password: this.state.password,
                email: this.state.username
            },
            callback: this.callback
        })
    }

    resetDefault = () => {
        this.setState({
            loading: false
        })
    }

    callback = (res) => {
        this.setState({
            loading: false
        })
        clearInterval(this.state.timer)
        if (res.data.code === 0) {
            sessionStorage.setItem('token', res.data.data)
            this.props.history.push('/user')
        } else {
            message.warning(res.data.message)
        }
    }

    keyup = (e) => {
        if (e.keyCode === 13) {
            this.sign()
        }
    }

    render() {
        let load = ''
        if (this.state.loading) {
            load = <Spin />
        } else {
            load = ''
        }

        return(
            <div className="bdg">
                <div className="box">
                    <h1></h1>
                    <div className={this.state.usernameWarn ? 'input-item blue' : 'input-item'} style={{'marginBottom':'30px'}}>
                        <input onKeyUp={this.keyup} type="text" placeholder="Username" value={this.state.username} onChange={this.handleChange.bind(this, 'username')} />
                    </div>
                    <div className={this.state.passwordWarn ? 'input-item blue' : 'input-item'}>
                        <input onKeyUp={this.keyup} type="password" placeholder="Password" value={this.state.password} onChange={this.handleChange.bind(this, 'password')} />
                    </div>
                    <button onClick={this.sign} disabled={this.state.loading}>Login{load}</button>
                </div>
            </div>
        )
    }
}

export default Login