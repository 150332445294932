import axios from './axios'
import md5 from 'js-md5'

export default {
    renewPassword({params, callback}) {
        params.password = md5(params.password)
        axios.post(`/email/user/renewPassword`, params).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    renewPin({params, callback}) {
        // params.password = md5(params.password)
        axios.post(`/email/user/renewPin`, params).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    }
}