// import { render } from '@testing-library/react'
import React from 'react'
import {Component} from 'react'
import '../assets/css/index.scss'
import image from '../assets/i.png'
import startImage from '../assets/s.png'
import centerImage from '../assets/l.png'
import http from '../api/rescue'
import { message, Spin } from 'antd'
import format from '../untils/format'
import axios from 'axios'

var map = null
// 所有点
var allMarker=[]
// 线的数据
var latlngArr = []
var line = null

var timer = null

var all_language = [
  {
    language: 'ar',
    messages:
      {
        backcamera: 'الكاميرا الخلفية',
        frontcamera: 'كاميرا أمامية',
        loadmore: 'تحميل المزيد',
        soundStart: 'مقاطع صوتية (تسجيلات ',
        soundEnd: ')',
        location: 'المتمركزة',
        copy: 'ينسخ',
        cancel: 'لالغاء',
        emergencystart: 'صور الطوارئ (صور',
        gmap: 'خرائط جوجل',
        amap: 'خرائط آبل',
        none: 'لا توجد معلومات عن الموقع',
        call: 'اتصل بـ "000"'
      }
  },
  {
    language: 'de',
    messages:
      {
        backcamera: 'Rückfahrkamera',
        frontcamera: 'Vordere Kamera',
        loadmore: 'Mehr laden',
        soundStart: 'Notfallbilder (',
        soundEnd: ' Bilder)',
        location: 'Lage',
        copy: 'Kopieren',
        cancel: 'abbrechen',
        emergencystart: 'Notfallbilder (',
        emergencyend: ' Bilder)',
        gmap: 'Google Maps',
        amap: 'Apple-Karten',
        none: 'Keine Standortinformationen',
        call: '"000" anrufen'
      }
  },
  {
    language: 'fr',
    messages:
      {
        backcamera: 'Caméra arrière',
        frontcamera: 'Caméra frontale',
        loadmore: 'charger plus',
        soundStart: 'Extraits sonores (',
        soundEnd: ' enregistrements)',
        location: 'Emplacement',
        copy: 'Copie',
        cancel: 'annuler',
        emergencystart: "Images d'urgence (",
        emergencyend: ' images)',
        gmap: 'Google Maps',
        amap: 'Cartes Apple',
        none: 'Aucune information de localisation',
        call: 'Appelez "000"'
      }
  },
  {
    language: 'fr-FR',
    messages:
      {
        backcamera: 'Caméra arrière',
        frontcamera: 'Caméra frontale',
        loadmore: 'charger plus',
        soundStart: 'Extraits sonores (',
        soundEnd: ' enregistrements)',
        location: 'Emplacement',
        copy: 'Copie',
        cancel: 'annuler',
        emergencystart: "Images d'urgence (",
        emergencyend: ' images)',
        gmap: 'Google Maps',
        amap: 'Cartes Apple',
        none: 'Aucune information de localisation',
        call: 'Appelez "000"'
      }
  },
  {
    language: 'fr-fr',
    messages:
      {
        backcamera: 'Caméra arrière',
        frontcamera: 'Caméra frontale',
        loadmore: 'charger plus',
        soundStart: 'Extraits sonores (',
        soundEnd: ' enregistrements)',
        location: 'Emplacement',
        copy: 'Copie',
        cancel: 'annuler',
        emergencystart: "Images d'urgence (",
        emergencyend: ' images)',
        gmap: 'Google Maps',
        amap: 'Cartes Apple',
        none: 'Aucune information de localisation',
        call: 'Appelez "000"'
      }
  },

  {
    language: 'ru',
    messages:
      {
        backcamera: 'задняя камера',
        frontcamera: 'Передняя камера',
        loadmore: 'Загрузи больше',
        soundStart: 'Экстренные изображения (',
        soundEnd: ' изображений)',
        location: 'Расположение',
        copy: 'Копировать',
        cancel: 'Отмена',
        emergencystart: 'Экстренные изображения (',
        emergencyend: ' изображений)',
        gmap: 'Карты Гугл',
        amap: 'Карты Apple',
        none: 'Нет информации о местоположении',
        call: 'Звонок "000"'
      }
  },
  {
    language: 'it',
    messages:
      {
        backcamera: 'Videocamera posteriore',
        frontcamera: 'Fotocamera frontale',
        loadmore: 'Posizione',
        soundStart: 'Clip audio (',
        soundEnd: ' gestione dei record)',
        location: 'Posizione',
        copy: 'copia',
        cancel: 'Annulla',
        emergencystart: 'Immagini di emergenza (',
        emergencyend: ' immagini)',
        gmap: 'Google Maps',
        amap: 'Mappe di mele',
        none: 'Nessuna informazione sulla posizione',
        call: 'Chiama "000"'
      }
  },
  {
    language: 'it-IT',
    messages:
      {
        backcamera: 'Videocamera posteriore',
        frontcamera: 'Fotocamera frontale',
        loadmore: 'Posizione',
        soundStart: 'Clip audio (',
        soundEnd: ' gestione dei record)',
        location: 'Posizione',
        copy: 'copia',
        cancel: 'Annulla',
        emergencystart: 'Immagini di emergenza (',
        emergencyend: ' immagini)',
        gmap: 'Google Maps',
        amap: 'Mappe di mele',
        none: 'Nessuna informazione sulla posizione',
        call: 'Chiama "000"'
      }
  },
  {
    language: 'it-it',
    messages:
      {
        backcamera: 'Videocamera posteriore',
        frontcamera: 'Fotocamera frontale',
        loadmore: 'Posizione',
        soundStart: 'Clip audio (',
        soundEnd: ' gestione dei record)',
        location: 'Posizione',
        copy: 'copia',
        cancel: 'Annulla',
        emergencystart: 'Immagini di emergenza (',
        emergencyend: ' immagini)',
        gmap: 'Google Maps',
        amap: 'Mappe di mele',
        none: 'Nessuna informazione sulla posizione',
        call: 'Chiama "000"'
      }
  },


  {
    language: 'hi',
    messages:
      {
        backcamera: 'पृष्ठ कैमरा',
        frontcamera: 'सामने का कैमरा',
        loadmore: 'और लोड करें',
        soundStart: 'ध्वनि क्लिप (',
        soundEnd: ' रिकॉर्डिंग)',
        location: 'स्थान',
        copy: 'प्रतिलिपि',
        cancel: 'रद्द करना',
        emergencystart: 'आपातकालीन छवियां (',
        emergencyend: ' छवियां)',
        gmap: 'गूगल मानचित्र',
        amap: 'एप्पल मैप्स',
        none: 'स्थान की जानकारी नहीं',
        call: '"000" पर कॉल करें'
      }
  },
  {
    language: 'ENGLISH',
    messages:
      {
        backcamera: 'Back camera',
        frontcamera: 'Front camera',
        loadmore: 'Load more',
        soundStart: 'Sound clips (',
        soundEnd: ' recordings)',
        location: 'Location',
        copy: 'Copy',
        cancel: 'Cancel',
        emergencystart: 'Emergency images (',
        emergencyend: 'images)',
        gmap: 'Google maps',
        amap: 'Apple maps',
        none: 'No location information',
        call: 'Call “000”'
      }
  }



]
class Rescue extends Component{
    constructor() {
        super()
        this.state = {
            lang: '',
            title: '',
            /********************************获取系统语言start*******************************/
            titleInformations: {
              backcamera: '',
              frontcamera: '',
              loadmore: '',
              soundStart: '',
              soundEnd: '',
              location: '',
              copy: '',
              cancel: ''      
            },
            /********************************获取系统语言end*******************************/
            loadingPic1: true,
            loadingPic2: true,
            loadingAudio: true,
            
            playerIndex: null,
            playerTime: 0 ,
            voiceUrl:'',

            page1: 1,
            page2: 1,
            page3: 1,

            frontPicture: [],
            backPicture: [],

            total1: 0,
            total2: 0,
            total3: 0,

            message: '',
            copytext: '',

            audioInfomation: [],
            audioNoRepeat: [],

            advice: 'no-yet',
            showAlert: false,

            lat: null,
            lon: null
        }
    }

    componentDidMount () {
        /********************************获取系统语言start*******************************/
        let lang = navigator.language || navigator.userLanguage
        // console.log('打印语言')
        // console.log(lang)
        if (lang.indexOf('-') !== -1)
          lang = lang.split('-')[0]
        // console.log(lang)
        if (lang === 'ar' || lang === 'de' || lang === 'fr' || lang === 'ru' || lang === 'it' || lang === 'hi' || lang === 'fr-FR' || lang === 'it-IT' || lang === 'fr-fr' || lang === 'it-it') {
          let item = all_language.find((i) => {
            return i.language === lang
          })
          this.setState({
            titleInformations: item.messages,
            lang: item.language
          })
        } else {
          let item = all_language.find((i) => {
            return i.language === 'ENGLISH'
          })

          this.setState({
            titleInformations: item.messages,
            lang: item.language
          })
        }
        /********************************获取系统语言end*******************************/
        clearTimeout(timer)
        map = null
        // 所有点
        allMarker=[]
        // 线的数据
        latlngArr = []
        line = null
        // 所有数据
        /*let data = [{lat: -46.41529141534711, lng: 168.36202576336592}, {lat: -46.4137769456175, lng: 168.36278047223678}, 
        {lat: -46.413863405977075, lng: 168.36175214293857}, {lat: -46.41314634171288, lng: 168.361961082421}]
        this.loadMap([{lat: -46.41529141534711, lng: 168.36202576336592}])

        setTimeout(() => {
          this.drawLine(data)
        }, 5000)*/
        if (/Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)) {
          // console.log('phone')
          this.setState({
            advice: 'phone'
          })
        } else {
          this.setState({
            advice: 'pc'
          })
        }
        if (!document.getElementById("googleMapScript")) {
            const googleMapScript = document.createElement('script')
            googleMapScript.id = "googleMapScript"
            googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC40TIlTPqAP4dpavVrddyyTKNEsDilJT0&callback=initMap&force=lite`      
            window.document.body.appendChild(googleMapScript)
        }
        this.setState({
          frontPicture: [],
          backPicture: [],
          audioInfomation: [],
          total1: 0,
          total2: 0,
          total3: 0
        })
        if (!this.props.match.params.id)
          return
        // 获取前置图片
        http.getPicture({
          params: {
            uuid: this.props.match.params.id,
            page: this.state.page1,
            size: 6,
            type: 0
          },
          callback: this.frontCallback
        })
        // 获取后置图片
        http.getPicture({
          params: {
            uuid: this.props.match.params.id,
            page: this.state.page2,
            size: 6,
            type: 2
          },
          callback: this.backCallback
        })
        // 获取音频 
        http.getAudios({
          params: {
            uuid: this.props.match.params.id,
            page: this.state.page2,
            size: 3
          },
          callback: this.audioCallback
        })
        // 获取位置
        http.getPosition({
          params: {
            uuid: this.props.match.params.id,
            time: 1
          },
          callback: this.posCallback
        })

        // 获取联系人信息
        http.getInfo({
          params: {
            uuid: this.props.match.params.id
          },
          callback: this.infoCallback
        })
        window.scrollTo(0, 0)
    }

    infoCallback = (res) => {
      let lang = navigator.language || navigator.userLanguage
      if (lang.indexOf('-') !== -1)
      lang = lang.split('-')[0]

      // console.log('-----------------')
      // console.log(lang)

      if (res.data.code === 0) {
        if (lang === 'ar') {
          let date = format.formatTimeZero(res.data.data.time) 
          let time = format.hourMinuteSecond(res.data.data.time).time
          let amOrpm = format.hourMinuteSecond(res.data.data.time).type
          if (amOrpm === 'AM')
            amOrpm = 'صباحًا بتاريخ'
          else
            amOrpm = 'مساءً في'
          this.setState({
            message: `${res.data.data.userName} بتنشيط زر الذعر في الساعة ${time} ${amOrpm} ${date}.`
          })
        } else if (lang === 'de') {
          let date = format.formatTimeZero2(res.data.data.time) 
          let time = format.hourMinuteSecond(res.data.data.time).time
          let amOrpm = format.hourMinuteSecond(res.data.data.time).type
          if (amOrpm === 'AM')
            amOrpm = 'am'
          else
            amOrpm = 'pm'
          this.setState({
            message: `${res.data.data.userName} löste ${amOrpm} ${date} um ${time} Uhr den Panikknopf aus.`
          })
        } else if (lang === 'fr' || lang === 'fr-FR' || lang === 'fr-fr') {
          let date = format.formatTimeZero(res.data.data.time) 
          let time = format.hourMinuteSecond(res.data.data.time).time
          this.setState({
            message: `${res.data.data.userName} a déclenché le bouton Panique à ${time} le ${date}.`
          })
        } else if (lang === 'ru') {
          let date = format.formatTimeZero2(res.data.data.time) 
          let time = format.hourMinuteSecond(res.data.data.time).time
          this.setState({
            message: `${res.data.data.userName} вызвало тревожную кнопку в ${time} ${date}.`
          })
        } else if (lang === 'it' || lang === 'it-IT' || lang === 'it-it') {
          let date = format.formatTimeZero(res.data.data.time) 
          let time = format.hourMinuteSecond(res.data.data.time).time
          this.setState({
            message: `${res.data.data.userName} ha attivato il pulsante Panico alle ${time} del ${date}.`
          })
        } else if (lang === 'hi') {
          let date = format.formatTimeZero(res.data.data.time) 
          let time = format.hourMinuteSecond(res.data.data.time).time
          this.setState({
            message: `${res.data.data.userName} ने ${date} को रात ${time} बजे पैनिक बटन को ट्रिगर किया।`
          })
        } else {
          let date = format.formatTimeZero(res.data.data.time) 
          let time = format.hourMinuteSecond(res.data.data.time).time
          let amOrpm = format.hourMinuteSecond(res.data.data.time).type
          if (amOrpm === 'AM')
            amOrpm = 'AM'
          else
            amOrpm = 'PM'
          this.setState({
            message: `${res.data.data.userName} triggered the Panic button at ${time}${amOrpm} on ${date}.`
          })
        }
      } else {
        message.warning(res.data.message)
      }
    }

    posCallback = (res, time) => {
      if (res.data.code === 0) {
          // console.log(this.props.match.params.id)
          if (time === 1) {
            // 首次拿到经纬度需要加载地图
            if (res.data.data === null || res.data.data.length === 0) {
              // console.log('位置1')
              timer = setTimeout(() => {
                http.getPosition({
                  params: {
                    uuid: this.props.match.params.id,
                    time: 1
                  },
                  callback: this.posCallback
                })
              }, 30000)
              return
            }
            this.loadMap(res.data.data)
            // console.log('位置2')
            timer = setTimeout(() => {
              http.getPosition({
                params: {
                  uuid: this.props.match.params.id,
                  time: 2
                },
                callback: this.posCallback
              })
            }, 30000)
          }
          if (time === 2) {
            if (res.data.data === null || res.data.data.length === 0)
              return
            // 第二次拿到经纬度开始，不需要重载地图，只需把现有的路线跟点清除，重新绘制
            this.drawLine(res.data.data)
            // console.log('位置3')
            timer = setTimeout(() => {
              http.getPosition({
                params: {
                  uuid: this.props.match.params.id,
                  time: 2
                },
                callback: this.posCallback
              })
            }, 30000)
          }
          
          const url = `https://maps.googleapis.com/maps/api/geocode/json?language=${this.state.lang ? this.state.lang : 'English'}&latlng=${res.data.data[res.data.data.length - 1].lat},${res.data.data[res.data.data.length - 1].lon}&key=AIzaSyC40TIlTPqAP4dpavVrddyyTKNEsDilJT0`
          axios.defaults.headers.common = {}
          axios.get(url, {
              credentials: false,
              emulateJSON: true,
              emulateHTTP:true
          }).then(response => {
              if (response.status == 200 && response.data.results && response.data.results[0]) {
                  let address_components_result = ''
                  response.data.results[0].address_components.forEach(i => {
                      address_components_result =  address_components_result === '' ? i.long_name : `${address_components_result}, ${i.long_name}`
                  })
                  this.setState({
                    copytext: address_components_result
                  })
              }
          }).catch(responseError => {
          });

          this.setState({
            lat: res.data.data[res.data.data.length - 1].lat,
            lon: res.data.data[res.data.data.length - 1].lon
          })
      } else {
        message.warning(res.data.message)
      }
    }

    frontCallback = (res, next) => {
      if (!next) {
        this.setState({
          loadingPic1: false
        })
      }
      if (res.data.code === 0) {
        // 数组去重拼在一起
        let img = []
        res.data.data.list.forEach(item => {
          img.push(item.url)
        })
        let a = new Set(this.state.frontPicture);
        let b = new Set(img);
        let arr = new Set([...a, ...b])
        arr = Array.from(arr)
        if (res.data.data.list.length === 0 || this.state.frontPicture.length === arr.length) {
          //message.warning('No more data for now.')
        }
        let total = typeof(res.data.data.total) === 'string' ? Number(res.data.data.total) : res.data.data.total
        this.setState({
          frontPicture: arr,
          total1: total
        }, () => {
          // 如果需要加载下一页
          if (next === true && arr.length < total && res.data.data.list.length > 0) {
            // console.log(9999)
            let temppage = this.state.page1 + 1
            this.setState({
              page1: temppage
            }, () => {
              // console.log(this.state.page1)
              http.getPicture({
                params: {
                  uuid: this.props.match.params.id,
                  page: this.state.page1,
                  size: 30,
                  type: 0
                },
                callback: this.frontCallback
              })
            })
          } else if (next === true && arr.length === total) {
            this.setState({
              loadingPic1: false
            })
          }
        })
      } else {
          message.warning(res.data.message)
      }
    }
  

    loadFront = () => {
      this.setState({
        loadingPic1: true
      })
      // 情况1，第一页刚好加载完
      if (this.state.page1 === 1 && this.state.frontPicture.length === 30) {
        // 加载下一页
        // console.log('情况1111')
        let temp = this.state.page1 + 1
        this.setState({
          page1: temp
        }, () => {
          http.getPicture({
            params: {
              uuid: this.props.match.params.id,
              page: this.state.page1,
              size: 30,
              type: 0
            },
            callback: this.frontCallback
          })
        })
      }
      // 情况2，第一页没加载完
      else if (this.state.page1 === 1 && this.state.frontPicture.length < 30) {
        // 加载下一页
        // console.log('情况2')
        // 继续加载当前页
        http.getPicture({
          params: {
            uuid: this.props.match.params.id,
            page: this.state.page1,
            size: 30,
            type: 0,
            next: true // 加载完当前页，如果数据量比total小，继续加载下一页
          },
          callback: this.frontCallback
        })
      }
      // 情况3，第n页加载完了
      else if (this.state.page1 !== 1 && this.state.frontPicture.length === this.state.page1 * 30) {
        // console.log('情况3')
        let temp = this.state.page1 + 1
        this.setState({
          page1: temp
        }, () => {
          http.getPicture({
            params: {
              uuid: this.props.match.params.id,
              page: this.state.page1,
              size: 30,
              type: 0
            },
            callback: this.frontCallback
          })
        })
      }
      // 情况4，第n页没加载完
      else if (this.state.page1 !== 1 && this.state.frontPicture.length < this.state.page1 * 30) {
        // console.log('情况4888')
        // 继续加载当前页
        http.getPicture({
          params: {
            uuid: this.props.match.params.id,
            page: this.state.page1,
            size: 30,
            type: 0,
            next: true // 加载完当前页，如果数据量比total小，继续加载下一页
          },
          callback: this.frontCallback
        })
      }
    }

    loadAdo = () => {
      this.setState({
        loadingAudio: true
      })
      // console.log(this.state.audioInfomation.length)
      // console.log(this.state.total3)
      // console.log((this.state.page3 - 1) * 6 + 3)
      // 情况1，第一页刚好加载完
      if (this.state.page3 === 1 && this.state.audioInfomation.length === 6) {
        // console.log('情况1')
        // 加载下一页
        let temp = this.state.page3 + 1
        this.setState({
          page3: temp
        }, () => {
          http.getAudios({
            params: {
              uuid: this.props.match.params.id,
              page: this.state.page3,
              size: 6
            },
            callback: this.audioCallback
          })
        })
      }// 情况2，第一页没加载完
      else if (this.state.page3 === 1 && this.state.audioInfomation.length < 6) {
        // console.log('情况2')
        // 继续加载当前页
        http.getAudios({
          params: {
            uuid: this.props.match.params.id,
            page: this.state.page3,
            size: 6,
            next: true
          },
          callback: this.audioCallback
        })
      }
      // 情况3，第n页加载完了
      else if (this.state.page3 !== 1 && this.state.audioInfomation.length === this.state.page3 * 6) {
        // 加载下一页
        // console.log('情况3')
        let temp = this.state.page3 + 1
        this.setState({
          page3: temp
        }, () => {
          http.getAudios({
            params: {
              uuid: this.props.match.params.id,
              page: this.state.page3,
              size: 6
            },
            callback: this.audioCallback
          })
        })
      }
      // 情况4，第n页没加载完
      else if (this.state.page3 !== 1 && this.state.audioInfomation.length < this.state.page3 * 6) {
        // console.log('情况4')
        // 继续加载当前页
        http.getAudios({
          params: {
            uuid: this.props.match.params.id,
            page: this.state.page3,
            size: 6,
            next: true
          },
          callback: this.audioCallback
        })
      }
    }
    backFront = () => {
      this.setState({
        loadingPic2: true
      })
      // 情况1，第一页刚好加载完
      if (this.state.page2 === 1 && this.state.backPicture.length === 30) {
        // 加载下一页
        // console.log('情况1')
        let temp = this.state.page2 + 1
        this.setState({
          page2: temp
        }, () => {
          http.getPicture({
            params: {
              uuid: this.props.match.params.id,
              page: this.state.page2,
              size: 30,
              type: 2
            },
            callback: this.backCallback
          })
        })
      }
      // 情况2，第一页没加载完
      else if (this.state.page2 === 1 && this.state.backPicture.length < 30) {
        // console.log('情况2')
        // 继续加载当前页
        http.getPicture({
          params: {
            uuid: this.props.match.params.id,
            page: this.state.page2,
            size: 30,
            type: 2,
            next: true // 加载完当前页，如果数据量比total小，继续加载下一页
          },
          callback: this.backCallback
        })
      }
      // 情况3，第n页加载完了
      else if (this.state.page2 !== 1 && this.state.backPicture.length === this.state.page2 * 30) {
        // console.log('情况3')
        let temp = this.state.page2 + 1
        this.setState({
          page2: temp
        }, () => {
          http.getPicture({
            params: {
              uuid: this.props.match.params.id,
              page: this.state.page2,
              size: 30,
              type: 2
            },
            callback: this.backCallback
          })
        })
      }
      // 情况4，第n页没加载完
      else if (this.state.page2 !== 1 && this.state.backPicture.length < this.state.page2 * 30) {
        // console.log('情况4')
        // 继续加载当前页
        http.getPicture({
          params: {
            uuid: this.props.match.params.id,
            page: this.state.page2,
            size: 30,
            type: 2,
            next: true // 加载完当前页，如果数据量比total小，继续加载下一页
          },
          callback: this.backCallback
        })
      }
    }
    backCallback = (res, next) => {
      if (!next) {
        this.setState({
          loadingPic2: false
        })
      }
      if (res.data.code === 0) {
        let img = []
        res.data.data.list.forEach(item => {
          img.push(item.url)
        })
        let a = new Set(this.state.backPicture);
        let b = new Set(img);
        let arr = new Set([...a, ...b])
        arr = Array.from(arr)
        if (res.data.data.list.length === 0 || this.state.backPicture.length === arr.length) {
          // message.warning('No more data for now.')
        }
        let total = typeof(res.data.data.total) === 'string' ? Number(res.data.data.total) : res.data.data.total
        this.setState({
          backPicture: arr,
          total2: total
        }, () => {
          // 如果需要加载下一页
          if (next === true && arr.length < total && res.data.data.list.length > 0) {
            let temppage = this.state.page2 + 1
            this.setState({
              page2: temppage
            }, () => {
              // console.log(this.state.page2)
              http.getPicture({
                params: {
                  uuid: this.props.match.params.id,
                  page: this.state.page2,
                  size: 30,
                  type: 2
                },
                callback: this.backCallback
              })
            })
          } else if (next === true && arr.length === total) {
            this.setState({
              loadingPic2: false
            })
          }
        })
      } else {
          message.warning(res.data.message)
      }
    }

    audioCallback = (res, next) => {
      if (!next) {
        this.setState({
          loadingAudio: false
        })
      }
      if (res.data.code === 0) {
        let tempInfomation = this.state.audioInfomation
        let url = []
        res.data.data.list.forEach(item => {
          url.push(item.url)
          // console.log(this.state.audioNoRepeat)

          if (this.state.audioNoRepeat.indexOf(item.url) == -1) {
            if (item.seconds && item.seconds !== null && item.seconds !== undefined) {
              tempInfomation.push({
                start: 0,
                cur: '00:' + (item.seconds.length < 2 ? '0'+item.seconds : item.seconds),
                url: item.url
              })
            }
            else {
              tempInfomation.push({
                start: 0,
                cur: '00:10',
                url: item.url
              })
            }
          }

        })

        let a = new Set(this.state.audioNoRepeat);
        let b = new Set(url);
        let arr = new Set([...a, ...b])
        arr = Array.from(arr)

        let total = typeof(res.data.data.total) === 'string' ? Number(res.data.data.total) : res.data.data.total
        this.setState({
          audioInfomation: tempInfomation,
          total3: total,
          audioNoRepeat: arr
        }, () => {
          // 如果需要加载下一页
          if (next === true && tempInfomation.length < total && res.data.data.list.length > 0) {
            let temppage = this.state.page3 + 1
            this.setState({
              page3: temppage
            }, () => {
              http.getAudios({
                params: {
                  uuid: this.props.match.params.id,
                  page: this.state.page3,
                  size: this.state.page3 === 1 ? 3 : 6
                },
                callback: this.audioCallback
              })
            })
          } else if (next === true && tempInfomation.length === total) {
            this.setState({
              loadingAudio: false
            })
          }
        })
      } else {
          message.warning(res.data.message)
      }
    }

    loadMap(data){
      var _doc=document.getElementsByTagName('head')[0];
      var script=document.createElement('script');
      script.setAttribute('type','text/javascript');
      script.setAttribute('src','https://maps.googleapis.com/maps/api/js?key=AIzaSyC40TIlTPqAP4dpavVrddyyTKNEsDilJT0&callback=initMap&force=lite');
      _doc.appendChild(script);
      var that = this
      script.onload=script.onreadystatechange=function(){
        if(!this.readyState||this.readyState=='loaded'||this.readyState=='complete'){
          that.initMap(data)
        }
        script.onload=script.onreadystatechange=null;
      }
    }

    initMap (data) {
        var options = {
          zoom: 16,
          center: new window.google.maps.LatLng(data[data.length - 1].lat, data[data.length - 1].lon),
          // mapTypeId: window.google.maps.MapTypeId.ROADMAP,
          scaleControl: false,
          mapTypeControl: false,
          zoomControl: false,
          streetViewControl: false,
          styles: [
            { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
            { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
            { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
            {
              featureType: "administrative.locality",
              elementType: "labels.text.fill",
              stylers: [{ color: "#d59563" }],
            },
            {
              featureType: "poi",
              elementType: "labels.text.fill",
              stylers: [{ color: "#d59563" }],
            },
            {
              featureType: "poi.park",
              elementType: "geometry",
              stylers: [{ color: "#263c3f" }],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.fill",
              stylers: [{ color: "#6b9a76" }],
            },
            {
              featureType: "road",
              elementType: "geometry",
              stylers: [{ color: "#38414e" }],
            },
            {
              featureType: "road",
              elementType: "geometry.stroke",
              stylers: [{ color: "#212a37" }],
            },
            {
              featureType: "road",
              elementType: "labels.text.fill",
              stylers: [{ color: "#9ca5b3" }],
            },
            {
              featureType: "road.highway",
              elementType: "geometry",
              stylers: [{ color: "#746855" }],
            },
            {
              featureType: "road.highway",
              elementType: "geometry.stroke",
              stylers: [{ color: "#1f2835" }],
            },
            {
              featureType: "road.highway",
              elementType: "labels.text.fill",
              stylers: [{ color: "#f3d19c" }],
            },
            {
              featureType: "transit",
              elementType: "geometry",
              stylers: [{ color: "#2f3948" }],
            },
            {
              featureType: "transit.station",
              elementType: "labels.text.fill",
              stylers: [{ color: "#d59563" }],
            },
            {
              featureType: "water",
              elementType: "geometry",
              stylers: [{ color: "#17263c" }],
            },
            {
              featureType: "water",
              elementType: "labels.text.fill",
              stylers: [{ color: "#515c6d" }],
            },
            {
              featureType: "water",
              elementType: "labels.text.stroke",
              stylers: [{ color: "#17263c" }],
            },
          ]
        }

        // 初始化地图
        map = new window.google.maps.Map(document.getElementById('map'), options)

        this.drawLine(data)

    }

    drawLine(data) {
      // console.log(data)
      if(allMarker.length>0){
        allMarker.forEach(function(item){
          item.setMap(null)
        })
        line.setMap(null)
      }
      map.setCenter(new window.google.maps.LatLng(data[data.length - 1].lat, data[data.length - 1].lon))
      //画出线上的点
      for (var i = 0; i < data.length; i++) {
        var marker = new window.google.maps.Marker({
            position: {lat:data[i].lat, lng:data[i].lon},
            map: map,
            icon: (i === 0 && i === data.length - 1) ? startImage : i === 0 ? startImage : i === data.length - 1 ? image : centerImage
        });
        allMarker.push(marker)
      }

      for (var i = 0; i < data.length; i++) {
        latlngArr[i] = new window.google.maps.LatLng(data[i].lat, data[i].lon);
      }

      //画线
      line = new window.google.maps.Polyline({
        path: latlngArr,
        strokeColor: '#3063F0',
        strokeOpacity: .8,
        strokeWeight: 5,
        map: map,
      })
    }

    audio = new Audio()

    isListening = false

    playerMp3 = (msg, index) => {
        // msg = 'https://s3-ap-southeast-2.amazonaws.com/au.com.safie/files/0041b7813e3c41468f52bf7bb39e7b19'
        if (this.isListening === true) {
          this.audio.removeEventListener('timeupdate',function(){})
        }
        this.setState({
            playerIndex: index
        })
        this.audio.loop = false; 
        this.audio.addEventListener('ended', () => { //播放完毕
            this.audio.currentTime = 0
            // 播放完了记录总长
            let tempInfo = this.state.audioInfomation
            tempInfo[index].start = 0
            tempInfo[index].all = tempInfo[index].cur
            this.setState({
                voiceUrl: '',
                playerIndex: null,
                audioInfomation: tempInfo
            })
        }, false);
        if(this.state.voiceUrl !== msg){
            // this.audio.currentTime = 0;
            this.audio.src = msg;
            this.audio.load()
            this.setState({
                voiceUrl: msg
            })
            // console.log(888)
        }
        if(this.audio.paused){ // 暂停
          this.audio.currentTime = this.state.audioInfomation[index].start
          // this.audio.currentTime = 270
          // console.log(99999999)
          // console.log(this.audio)
          this.audio.play();
        }else{
            this.setState({
                playerIndex: null
            })
            this.audio.pause()
        }
        // 监听时长
        this.isListening = true
        this.audio.addEventListener('timeupdate', () => {
          if (this.state.playerIndex === null)
            return
          let _timeStamp = parseInt(this.audio.currentTime)
          let minute = parseInt(_timeStamp / 60) < 10 ? `0${parseInt(_timeStamp / 60)}` : parseInt(_timeStamp / 60)
          let second = _timeStamp - minute * 60 < 10 ? `0${_timeStamp - minute * 60}` : _timeStamp - minute * 60
          let final = `${minute}:${second}`
          let tempInfo = this.state.audioInfomation
          tempInfo[this.state.playerIndex].cur = final
          tempInfo[this.state.playerIndex].start = this.audio.currentTime
          this.setState({
            audioInfomation: tempInfo
          })
        })
    }

    copy = () => {
      if (this.state.copytext === '' || this.state.copytext === null) {
        message.warning(this.state.titleInformations.none)
        return
      }
      this.copyText(this.state.copytext)
    }

    copyText = (text) => {
      // 数字没有 .length 不能执行selectText 需要转化成字符串
      const textString = text.toString();
      let input = document.querySelector('#copy-input');
      if (!input) {
        input = document.createElement('input');
        input.id = "copy-input";
        input.readOnly = "readOnly";        // 防止ios聚焦触发键盘事件
        input.style.position = "absolute";
        input.style.left = "-1000px";
        input.style.zIndex = "-1000";
        document.body.appendChild(input)
      }
  
      input.value = textString;
      // ios必须先选中文字且不支持 input.select();
      selectText(input, 0, textString.length);
      // console.log(document.execCommand('copy'), 'execCommand');
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        message.success('Copy Success')
      }
      input.blur();
  
      // input自带的select()方法在苹果端无法进行选择，所以需要自己去写一个类似的方法
      // 选择文本。createTextRange(setSelectionRange)是input方法
      function selectText(textbox, startIndex, stopIndex) {
        if (textbox.createTextRange) {//ie
          const range = textbox.createTextRange();
          range.collapse(true);
          range.moveStart('character', startIndex);//起始光标
          range.moveEnd('character', stopIndex - startIndex);//结束光标
          range.select();//不兼容苹果
        } else {//firefox/chrome
          textbox.setSelectionRange(startIndex, stopIndex);
          textbox.focus();
        }
      }
    }

    openList = () =>  {
      this.setState({
        showAlert: true
      })
    }

    cancelOpenapp = () =>  {
      this.setState({
        showAlert: false
      })
    }

    openApp = () => {
      let browser = {
          versions: function () {
              let u = navigator.userAgent,
                  app = navigator.appVersion;
              return {
                  trident: u.indexOf('Trident') > -1, /*IE内核*/
                  presto: u.indexOf('Presto') > -1, /*opera内核*/
                  webKit: u.indexOf('AppleWebKit') > -1, /*苹果、谷歌内核*/
                  gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, /*火狐内核*/
                  mobile: !!u.match(/AppleWebKit.*Mobile.*/), /*是否为移动终端*/
                  ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), /*ios终端*/
                  // android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, /*android终端或者uc浏览器*/
                  android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1,
                  iPhone: u.indexOf('iPhone') > -1, /*是否为iPhone或者QQHD浏览器*/
                  iPad: u.indexOf('iPad') > -1, /*是否iPad*/
                  webApp: u.indexOf('Safari') == -1, /*是否web应该程序，没有头部与底部*/
                  souyue: u.indexOf('souyue') > -1,
                  superapp: u.indexOf('superapp') > -1,
                  weixin: u.toLowerCase().indexOf('micromessenger') > -1,
                  Safari: u.indexOf('Safari') > -1,
                  uc: u.indexOf('Linux') > -1
              };

          }(),
          language: (navigator.browserLanguage || navigator.language).toLowerCase()
      };
      if (browser.versions.ios) {
          window.location.href = `https://www.google.com/maps/dir/?api=1&destination=${this.state.lat},${this.state.lon}&travelmode=driving`
      }
      else if (browser.versions.android) {
          window.location.href = `https://www.google.com/maps/dir/?api=1&destination=${this.state.lat},${this.state.lon}&travelmode=driving`
      }
  }

  openApp3 = () => {
    window.location.href = `http://maps.apple.com/?daddr=${this.state.lat},${this.state.lon}&dirflg=d`
  }

    render() {
      let type = ""
      let browser = {
        versions: function () {
            let u = navigator.userAgent,
                app = navigator.appVersion;
            return {
                trident: u.indexOf('Trident') > -1, /*IE内核*/
                presto: u.indexOf('Presto') > -1, /*opera内核*/
                webKit: u.indexOf('AppleWebKit') > -1, /*苹果、谷歌内核*/
                gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, /*火狐内核*/
                mobile: !!u.match(/AppleWebKit.*Mobile.*/), /*是否为移动终端*/
                ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), /*ios终端*/
                // android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, /*android终端或者uc浏览器*/
                android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1,
                iPhone: u.indexOf('iPhone') > -1, /*是否为iPhone或者QQHD浏览器*/
                iPad: u.indexOf('iPad') > -1, /*是否iPad*/
                webApp: u.indexOf('Safari') == -1, /*是否web应该程序，没有头部与底部*/
                souyue: u.indexOf('souyue') > -1,
                superapp: u.indexOf('superapp') > -1,
                weixin: u.toLowerCase().indexOf('micromessenger') > -1,
                Safari: u.indexOf('Safari') > -1,
                uc: u.indexOf('Linux') > -1
            };

        }(),
        language: (navigator.browserLanguage || navigator.language).toLowerCase()
        };
        if (browser.versions.ios) {
            type = 'ios'
        }
        else if (browser.versions.android) {
            type = 'android'
        }

        let img1 = ''
        if (this.state.loadingPic1) {
            img1 = <div><ul className="pic">
                  {this.state.frontPicture.map((item, index) => {
                    return(
                    <li key={index}>
                      <img src={item} style={{'width':'100%'}} />
                    </li>
                    )
                  })}
              </ul>
              <div className="loading"><Spin /></div>
            </div>
        } else {
            img1 = <div><ul className="pic">
                  {this.state.frontPicture.map((item, index) => {
                    return(
                    <li key={index}>
                      <img src={item} style={{'width':'100%'}} />
                    </li>
                    )
                  })}
              </ul>
              <button className="load" onClick={this.loadFront}>{this.state.titleInformations.loadmore}</button>
            </div>
        }

        let img2 = ''
        if (this.state.loadingPic2) {
            img2 = <div><ul className="pic">
                  {this.state.backPicture.map((item, index) => {
                    return(
                    <li key={index}><img src={item} style={{'width':'100%'}} /></li>
                    )
                  })}
              </ul>
              <div className="loading"><Spin /></div>
            </div>
        } else {
            img2 = <div><ul className="pic">
                  {this.state.backPicture.map((item, index) => {
                    return(
                    <li key={index}><img src={item} style={{'width':'100%'}} /></li>
                    )
                  })}
              </ul>
              <button className="load" onClick={this.backFront}>{this.state.titleInformations.loadmore}</button>
            </div>
        }

        let _audio = ''
        if (this.state.loadingAudio) {
            _audio = <div><ul className="radio">
                  {this.state.audioInfomation.map((item, index) => {
                    return(
                      <li key={index}>
                        {this.state.playerIndex !== index ? <div className="icon" onClick={this.playerMp3.bind(this, item.url, index)}></div> :
                        <div className="icon2" onClick={this.playerMp3.bind(this, item.url, index)}></div>}
                        <div className="wave">
                            <audio preload="true" controls="controls" src={item.url}></audio>
                        </div>
                        <div className="time">{this.state.audioInfomation[index].all === this.state.audioInfomation[index].cur ? this.state.audioInfomation[index].all : this.state.audioInfomation[index].cur}</div>
                    </li>
                    )
                  })}
              </ul>
              <div className="loading"><Spin /></div>
            </div>
        } else {
            _audio = <div><ul className="radio">
                  {this.state.audioInfomation.map((item, index) => {
                    return(
                      <li key={index}>
                          {this.state.playerIndex !== index ? <div className="icon" onClick={this.playerMp3.bind(this, item.url, index)}></div> :
                          <div className="icon2" onClick={this.playerMp3.bind(this, item.url, index)}></div>}
                          <div className="wave">
                              <audio preload="true" controls="controls" src={item.url}></audio>
                          </div>
                          <div className="time">{this.state.audioInfomation[index].all === this.state.audioInfomation[index].cur ? this.state.audioInfomation[index].all : this.state.audioInfomation[index].cur}</div>
                      </li>
                    )
                  })}
              </ul>
              <button className="load" onClick={this.loadAdo}>{this.state.titleInformations.loadmore}</button>
            </div>
        }
        let logo = ''
        if (this.state.advice === 'phone') {
          logo = <div className="logo"></div>
        } else {
          logo = <div className="logo2"></div>
        }

        let wrap = ''
        if (this.state.advice === 'phone') {
          wrap = <div className="wrap">
            <div className="warn">{this.state.message}</div>
            <h1>{this.state.titleInformations.emergencystart}<span>{this.state.lang === 'ar' ? '(' : ''}
            {this.state.total1 + this.state.total2}
            {this.state.lang !== 'ar' ? this.state.titleInformations.emergencyend : ''}</span></h1>
            <h2>{this.state.titleInformations.backcamera}</h2>
            {img2}
            <h2>{this.state.titleInformations.frontcamera}</h2>
            {img1}
            <h1>{this.state.titleInformations.soundStart}<span>{this.state.lang === 'ar' ? '(' : ''}
              {this.state.total3}
              {this.state.lang !== 'ar' ? this.state.titleInformations.soundEnd : ''}</span></h1>
            {_audio}
            <h1>{this.state.titleInformations.location}</h1>
            <div className="address">
                <div className="text2" id="copy">{this.state.copytext}</div>
                <button className="copy" onClick={this.copy}>{this.state.titleInformations.copy}</button>
            </div>
            <div style={{'position':'relative'}}>
              <div className="map" id="map"></div>
              <div onClick={this.openList} className="jump"></div>
            </div>
            <a href='tel:000' className="load">Call “000”</a>
        </div>
        } else {
          wrap = <div className="wrap2">
            <div className="warn">{this.state.message}</div>
            <h1>{this.state.titleInformations.emergencystart}<span>{this.state.lang === 'ar' ? '(' : ''}
            {this.state.total1 + this.state.total2}
            {this.state.lang !== 'ar' ? this.state.titleInformations.emergencyend : ''}</span></h1>
            <div className="picarea clear">
              <div className="piclft">
                <h2>{this.state.titleInformations.backcamera}</h2>
                {img2}
              </div>
              <div className="picrgt">
                <h2>{this.state.titleInformations.frontcamera}</h2>
                {img1}
              </div>
            </div>
            <div className="picarea clear" style={{'marginBottom':'65px'}}>
              <div className="piclft">
              <h1>{this.state.titleInformations.soundStart}<span>{this.state.lang === 'ar' ? '(' : ''}
              {this.state.total3}
              {this.state.lang !== 'ar' ? this.state.titleInformations.soundEnd : ''}</span></h1>
              {_audio}
              </div>
              <div className="picrgt">
                <h1>{this.state.titleInformations.location}</h1>
                <div className="address">
                    <div className="text2" id="copy">{this.state.copytext}</div>
                    <button className="copy" onClick={this.copy}>{this.state.titleInformations.copy}</button>
                </div>
                <div className="map" id="map"></div>
              </div>
            </div>
            <a href='tel:000' className="load">{this.state.titleInformations.call}</a>
          </div>
        }

        let alert = ''
        if (this.state.showAlert === true) {
          alert = <div className="al-wp">
            <div className="al-bg">
              <div className="line" onClick={this.openApp3} style={{'display': type === 'ios' ? 'block' : 'none'}}>{this.state.titleInformations.amap}</div>
              <div className="line" onClick={this.openApp} style={{'marginBottom': '.2rem'}}>{this.state.titleInformations.gmap}</div>
              <div className="line" onClick={this.cancelOpenapp}>Cancel</div>
            </div>
          </div>
        }
        return (
            <div className="recude">
                {logo}
                {wrap}
                {alert}
            </div>
        )
    }
}

export default Rescue