import axios from './axios'

export default {
    getPicture({params, callback}) {
        axios.get(`/Share/findEmergencyImage?uuid=${params.uuid}&page=${params.page}&size=${params.size}&type=${params.type}`).then(res => {
            if (params.next)
                callback(res, params.next)
            else
                callback(res, params.next)
        }).catch(err => {
            if (params.next)
                callback(err, params.next)
            else
                callback(err, params.next)
        })
    },
    getAudios({params, callback}) {
        axios.get(`/Share/findEmergencyAudio?uuid=${params.uuid}&page=${params.page}&size=${params.size}`).then(res => {
            if (params.next)
                callback(res, params.next)
            else
                callback(res, params.next)
        }).catch(err => {
            if (params.next)
                callback(err, params.next)
            else
                callback(err, params.next)
        })
    },
    /*getPosition({params, callback}) {
        axios.get(`/Share/getEmergencyContact?uuid=${params.uuid}`).then(res => {
            if (params.time)
                callback(res, params.time)
            else
                callback(res, params.time)
        }).catch(err => {
            if (params.time)
                callback(err, params.time)
            else
                callback(err, params.time)
        })
    },*/
    getPosition({params, callback}) {
        axios.post(`/home/getShareLocationRecords/${params.uuid}`).then(res => {
            if (params.time)
                callback(res, params.time)
            else
                callback(res, params.time)
        }).catch(err => {
            if (params.time)
                callback(err, params.time)
            else
                callback(err, params.time)
        })
    },
    getInfo({params, callback}) {
        axios.get(`/Share/getEmergencyInfo?uuid=${params.uuid}`).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    }
}