import React from 'react'
import {Component} from 'react'
import {HashRouter as Router, Route, Switch} from 'react-router-dom'
import Nav from '../components/common/nav'

class Index extends Component {
    constructor() {
        super()
    }
    render() {
        return(
            <Router>
                <div className="page">
                    <div className="top"><Nav parentProps={this.props} /></div>
                    <div className="btm"><Switch>
                        {this.props.routes.map((route, index) => {
                            return (
                                <Route path={route.path} key={index} props={this.props}
                                render = {props => (
                                    <route.component {...props} />
                                )}
                                />
                            )
                        })}
                    </Switch></div>
                </div>
            </Router>
        )
    }
}

export default Index