import axios from './axios'

export default {
    signIn({params, callback}) {
        axios.post('/oa/user/login', params).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    userList({params, callback}) {
        axios.post(`/oa/user/userList/${params.page}/${params.size}`, params.data).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    update({params, callback}) {
        axios.post('/oa/user/updateUserStatus', params.data).then(res => {
            callback(res, params.index)
        }).catch(err => {
            callback(err)
        })
    },
    delUser({params, callback}) {
        axios.delete(`/oa/user/deleteUser/${params}`).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    rewordList({params, callback}) {
        axios.post(`/oa/reward/rewardList/${params.page}/${params.size}`).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    addOrUpdateReward({params, callback}) {
        axios.post('/oa/reward/addOrUpdateReward', params).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    uploadBase64({params, callback}) {
        axios.post('/oa/pic/upload', params).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    updateReward({params, callback}) {
        axios.post('/oa/reward/updateRewardStatus', params.data).then(res => {
            callback(res, params.index)
        }).catch(err => {
            callback(err)
        })
    },
    delReword({params, callback}) {
        axios.delete(`/oa/reward/deleteReward/${params}`).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    infoReward({params, callback}) {
        axios.get(`/oa/reward/getRewardInfo/${params}`).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    redeemRecords({params, callback, cancelToken}) {
        axios.post(`/oa/redeemRecords/${params.page}/${params.size}/${params.sendStatus}`, {}, cancelToken).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    logout({callback}) {
        axios.post('/oa/user/logout').then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    send({params, callback}) {
        axios.post(`/oa/sendEmail`, params).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    versionList({params, callback, cancelToken}) {
        axios.post(`/oa/version/list/${params.page}/${params.size}`, {type: params.sendStatus}, cancelToken).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    addVersion({params, callback}) {
        axios.post('/oa/version/add', params).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    promotList({params, callback, cancelToken}) {
        axios.post(`/oa/promoteCode/promoteCodeList/${params.page}/${params.size}`, {}, cancelToken).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    promotAdd({params, callback}) {
        axios.post(`/oa/promoteCode/addPromoteCode`, params).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    delPromot({params, callback}) {
        axios.delete(`/oa/promoteCode/deletePromoteCode/${params}`).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    updatePromoteCode({params, callback}) {
        axios.post(`/oa/promoteCode/updatePromoteCode`, params.data).then(res => {
            callback(res, params.index)
        }).catch(err => {
            callback(err)
        })
    },
    getActivateHistoryList({params, callback, cancelToken}) {
        axios.post(`/oa/user/getActivateHistoryList/${params.page}/${params.size}`, {}, cancelToken).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    importActivateInfo({params, callback}) {
        axios.post(`/oa/user/importActivateInfo/${params.expirationDate}/${params.extraFreeDuration}`, params.param).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    uploadExcelfile({params, callback}) {
        axios.post('/oa/user/getActivateCount', params).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    setBasicQuotaReminder({params, callback}) {
        axios.post(`/oa/promoteCode/setBasicQuotaReminder`, params).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    }
}