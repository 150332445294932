import React from 'react'
import { Component } from 'react'
import http from '../../api/request'
import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons'
import { message, Form, Input, Button, Spin } from 'antd'

class Rewardadd extends Component {
    constructor() {
        super()
        this.state = {
            requesting: false,
            loading: false
        }
    }

    formRef = React.createRef()

    componentDidMount () {
        window.scrollTo(0, 0)
    }

    onFinish = (values) => {
        let data = {
            version: values.version,
            minVersion: values.minimumversion,
            updateDetails: values.description
        }
        data.type = this.props.match.params.id === '1' ? 1 : 2
        this.setState({
            requesting: true
        })
        http.addVersion({
            params: data,
            callback: this.callback
        })
        setTimeout(() => {
            if (this.state.requesting === true) {
                this.setState({
                    requesting: false
                })
            }
        }, 60000)
    }

    // 请求回调
    callback = (res) => {
        this.setState({
            requesting: false
        })
        if (res.data.code === 0) {
            localStorage.setItem('type',this.props.match.params.id)
            this.props.history.go(-1)
        } else {
            message.warning(res.data.message)
        }
    }

    onFinishFailed = (errorInfo) => {
        // console.log('Failed:', errorInfo);
    }


    // 上传回调
    uploadCallback = (res) => {
        this.setState({
            loading: false
        })
        if (res.data.code === 0) {
            this.setState({
                tempImg: res.data.data
            })
        } else if (res.data.code === 10001) {
            //message.warning(res.data.message)
            this.props.history.push('/login')
        } else if (res.data.code === 10002) {
            message.warning(res.data.message)
            this.props.history.push('/login')
        } else {
            message.warning(res.data.message)
        }
    }

    goback = () => {
        this.props.history.go(-1)
    }

    render() {
        const layout = {
            labelCol: {
              span: 6,
            },
            wrapperCol: {
              span: 14,
            },
        }
        const tailLayout = {
            wrapperCol: {
              offset: 6,
              span: 14,
            },
        }

        let loading = ''
        if (this.state.loading) {
            loading = <div className="loading"><LoadingOutlined /></div>
        }
        return (
            <div className="main">
                <div className="back"><span onClick={this.goback}><ArrowLeftOutlined /> Back</span></div>
                <div className="box">
                    <h1>Upload Version Details</h1>
                    <Form
                        {...layout}
                        name="basic"
                        ref={this.formRef}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        >
                        <Form.Item
                            label="Version"
                            name="version"
                            rules={[
                            {
                                required: true,
                                message: 'Please input Version!'
                            },
                            ]}
                        >
                            <Input maxLength={50} />
                        </Form.Item>

                        <Form.Item
                            label="Minimum Version"
                            name="minimumversion"
                            rules={[
                            {
                                required: true,
                                message: 'Please input Minimum Version!'
                            },
                            ]}
                        >
                            <Input maxLength={50} />
                        </Form.Item>

                        <Form.Item
                            label="Update Details"
                            name="description"
                            rules={[
                            {
                                required: true,
                                message: 'Please input details!'
                            },
                            ]}
                        >
                            <Input.TextArea showCount maxLength={1000} autoSize={{ minRows: 6, maxRows: 6 }} />
                        </Form.Item>


                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                            Create
                            </Button>
                            <Button style={{'marginLeft': '20px'}} onClick={this.goback}>
                            Cancel
                            </Button>
                        </Form.Item>
                    </Form>
                </div>

                {this.state.requesting ? <div className="loadmask"><Spin tip=""></Spin></div> : ''}
            </div>
        )
    }
}

export default Rewardadd