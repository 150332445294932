export default {
    formatTime (timestamp) {
        if (typeof(timestamp) === 'string')
            timestamp = Number(timestamp)

        var time = new Date(timestamp * 1000)
        var y = time.getFullYear()
        var m = time.getMonth() + 1
        var d = time.getDate()
        var result = `${d}/${m}/${y}`
        return result
    },
    formatTimeZero (timestamp) {
        if (typeof(timestamp) === 'string')
            timestamp = Number(timestamp)

        var time = new Date(timestamp * 1000)
        var y = time.getFullYear()
        var m = (time.getMonth() + 1) < 10 ? `0${time.getMonth() + 1}` : time.getMonth() + 1
        var d = time.getDate() < 10 ? `0${time.getDate()}` : time.getDate()
        var result = `${d}/${m}/${y}`
        return result
    },
    formatTimeZero2 (timestamp) {
        if (typeof(timestamp) === 'string')
            timestamp = Number(timestamp)

        var time = new Date(timestamp * 1000)
        var y = time.getFullYear()
        var m = (time.getMonth() + 1) < 10 ? `0${time.getMonth() + 1}` : time.getMonth() + 1
        var d = time.getDate() < 10 ? `0${time.getDate()}` : time.getDate()
        var result = `${d}.${m}.${y}`
        return result
    },
    // 格式化时间 时分秒分开（用）
    timestampToTime(timestamp) {
        let date = new Date(timestamp*1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        let Y = date.getFullYear() + ' ';
        let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '/';
        let D = (date.getDate() < 10 ? '0'+(date.getDate()) : date.getDate()) + '/';
        //let H =(date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours()) + ':';
        let H =(date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours());
        if(H -12 <0){ 
            H = H+ ':'+ (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + ' AM'; 
        }else if(H-12>0 && H-24<0){ 
            H = (H - 12) < 10?'0'+(H - 12)+ ':'+ (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + ' PM':(H - 12)+ ':'+ (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) +' PM'; 
        }else if(H-24 ==0){ 
            H ='00' +':'+ (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes())+ ' PM'; 
        }else if(H-12==0){
            H = H+ ':'+ (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + 'PM';
        }
        // let m = (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes());
        return H+' '+D+M+Y;
    },
    timestampToDate(timestamp) {
        if (typeof(timestamp) === 'string')
            timestamp = Number(timestamp)
        let date = new Date(timestamp*1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        let Y = date.getFullYear() + ' ';
        let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '/';
        let D = (date.getDate() < 10 ? '0'+(date.getDate()) : date.getDate()) + '/';
        // let m = (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes());
        return D+M+Y;
    },
    hourMinuteSecond(timestamp) {
        if (typeof(timestamp) === 'string')
            timestamp = Number(timestamp)

        let date = new Date(timestamp*1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        let H =(date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours());
        if(H -12 < 0){ 
            H = H+ ':'+ (date.getMinutes() < 10 ? '0'+(date.getMinutes())  :  date.getMinutes()) + ':' + (date.getSeconds() < 10 ? '0'+(date.getSeconds())  :  date.getSeconds()) + ' AM'; 
        }else if(H-12>=0){ 
            H = H+ ':'+ (date.getMinutes() < 10 ? '0'+(date.getMinutes())  :  date.getMinutes()) + ':' + (date.getSeconds() < 10 ? '0'+(date.getSeconds())  :  date.getSeconds()) + ' PM'; 
        }
        return {
            time: H.split(' ')[0],
            type: H.split(' ')[1]
        };

    },

}