import React from 'react'
import {Component} from 'react'
import { message, Spin } from 'antd'
import '../assets/css/index.scss'
import http from '../api/email'

var all_language = [
    {
      language: 'ar',
      messages:
        {
          a: 'إعادة تعيين كلمة المرور',
          b:'كلمة السر الجديدة',
          c: 'قم بتأكيد كلمة المرور الجديدة',
          d: 'إعادة تعيين كلمة المرور',
          e: 'اكتملت إعادة التعيين بنجاح!',
          f: 'يرجى تسجيل الدخول إلى SAFIE باستخدام بيانات الاعتماد المحدثة الخاصة بك.',
          g: 'كلمتا المرور غير متطابقتان',
          h: 'الرجاء إدخال كلمة مرور من 8 إلى 16 حرفًا'
        }
    },
    {
      language: 'de',
      messages:
        {
          a: 'Passwort zurücksetzen',
          b: 'Neues Passwort',
          c:'Bestätige neues Passwort',
          d: 'Passwort zurücksetzen',
          e: 'Zurücksetzen erfolgreich!',
          f: 'Bitte melden Sie sich mit den aktualisierten Zugangsdaten bei SAFIE an.',
          g: 'Die zwei Passwörter stimmen nicht überein',
          h: 'Bitte geben Sie ein Passwort mit 8-16 Zeichen ein'
        }
    },
    {
      language: 'fr',
      messages:
        {
          a: 'Password Reset',
          b: 'nouveau mot de passe',
          c: 'Confirmez le nouveau mot de passe',
          d: 'Réinitialiser le mot de passe',
          e: 'Réinitialisation réussie !',
          f: "Veuillez vous connecter à SAFIE avec des informations d'identification mises à jour.",
          g: 'les deux mots de passe ne correspondent pas',
          h: 'Veuillez entrer un mot de passe de 8 à 16 caractères'
        }
    },
    {
        language: 'fr-fr',
        messages:
          {
            a: 'Password Reset',
            b: 'nouveau mot de passe',
            c: 'Confirmez le nouveau mot de passe',
            d: 'Réinitialiser le mot de passe',
            e: 'Réinitialisation réussie !',
            f: "Veuillez vous connecter à SAFIE avec des informations d'identification mises à jour.",
            g: 'les deux mots de passe ne correspondent pas',
            h: 'Veuillez entrer un mot de passe de 8 à 16 caractères'
          }
      },

      {
        language: 'fr-FR',
        messages:
          {
            a: 'Password Reset',
            b: 'nouveau mot de passe',
            c: 'Confirmez le nouveau mot de passe',
            d: 'Réinitialiser le mot de passe',
            e: 'Réinitialisation réussie !',
            f: "Veuillez vous connecter à SAFIE avec des informations d'identification mises à jour.",
            g: 'les deux mots de passe ne correspondent pas',
            h: 'Veuillez entrer un mot de passe de 8 à 16 caractères'
          }
      },
  
    {
      language: 'ru',
      messages:
        {
          a: 'Восстановление пароля',
          b: 'Новый пароль',
          c: 'Подтвердите новый пароль',
          d: 'Сброс пароля',
          e: 'Сброс выполнен успешно!',
          f: 'Пожалуйста, войдите в SAFIE с обновленными учетными данными.',
          g: 'Два пароля не совпадают',
          h: 'Пожалуйста, введите пароль из 8-16 символов'
        }
    },
    {
      language: 'it',
      messages:
        {
          a: 'Reimpostazione della password',
          b: 'Nuova password',
          c: 'Conferma la nuova password',
          d: 'reimposta la tua password',
          e: 'Il ripristino ha funzionato',
          f: 'Accedi a SAFIE con le credenziali aggiornate.',
          g: 'Le due password non corrispondono',
          h: 'Immettere una password di 8-16 caratteri'
        }
    },
    {
        language: 'it-it',
        messages:
          {
            a: 'Reimpostazione della password',
            b: 'Nuova password',
            c: 'Conferma la nuova password',
            d: 'reimposta la tua password',
            e: 'Il ripristino ha funzionato',
            f: 'Accedi a SAFIE con le credenziali aggiornate.',
            g: 'Le due password non corrispondono',
            h: 'Immettere una password di 8-16 caratteri'
          }
      },

      {
        language: 'it-IT',
        messages:
          {
            a: 'Reimpostazione della password',
            b: 'Nuova password',
            c: 'Conferma la nuova password',
            d: 'reimposta la tua password',
            e: 'Il ripristino ha funzionato',
            f: 'Accedi a SAFIE con le credenziali aggiornate.',
            g: 'Le due password non corrispondono',
            h: 'Immettere una password di 8-16 caratteri'
          }
      },
  
  
    {
      language: 'hi',
      messages:
        {
          a: 'पासवर्ड रीसेट',
          b: 'नया पासवर्ड',
          c: 'नए पासवर्ड की पुष्टि करें',
          d: 'पासवर्ड रीसेट',
          e: 'रीसेट सफल!',
          f: 'कृपया अद्यतन क्रेडेंशियल के साथ SAFIE में लॉग इन करें।',
          g: 'दो पासवर्ड मेल नहीं खाते',
          h: 'कृपया 8-16 अक्षरों का पासवर्ड दर्ज करें'
        }
    },
    {
      language: 'ENGLISH',
      messages:
        {
          a: 'Password Reset',
          b: 'New Password',
          c: 'Confirm New Password',
          d: 'Reset Password',
          e: 'Reset successful!',
          f: 'Please login to SAFIE with the updated credentials.',
          g: 'The two password do not match',
          h: 'Please enter a password of 8-16 character'
        }
    }
  
  
  
  ]

  
class Security extends Component{
    constructor() {
        super()
        this.state = {
            psd: '',
            psdWarn: false,
            rpsd: '',
            rpsdWarn: false,
            loading: false,
            timer: null,
            success: false,
            email: null,
            session: null,

            lang: '',
            /********************************获取系统语言start*******************************/
            titleInformations: {
              a: '',
              b: '',
              c: '',
              d: '',
              e: '',
              f: ''
            },
            /********************************获取系统语言end*******************************/

        }
    }

    handleChange = (key, e) => {
        if (key === 'psd') {
            let temp = e.target.value
            //temp = temp.replace(/[\W]/g,'')
            this.setState({
                psd: temp
            }, () => {
                if (this.state.psd.replace(/\s+/g,'') !== '') {
                    this.setState({
                        psdWarn: false
                    })
                }
            })
        } else if (key === 'rpsd') {
            this.setState({
                rpsd: e.target.value
            }, () => {
                if (this.state.rpsd.replace(/\s+/g,'') !== '') {
                    this.setState({
                        rpsdWarn: false
                    })
                }
            })
        }
    }

    keyup = (e) => {
        if (e.keyCode === 13) {
            this.sign()
        }
    }

    resetDefault = () => {
        this.setState({
            loading: false
        })
    }

    componentDidMount () {
                /********************************获取系统语言start*******************************/
                let lang = navigator.language || navigator.userLanguage
                if (lang.indexOf('-') !== -1)
                  lang = lang.split('-')[0]
      
                // console.log('打印语言')
                // console.log(lang)
                if (lang === 'ar' || lang === 'de' || lang === 'fr' || lang === 'ru' || lang === 'it' || lang === 'hi' || lang === 'fr-FR' || lang === 'it-IT' || lang === 'fr-fr' || lang === 'it-it') {
                  let item = all_language.find((i) => {
                    return i.language === lang
                  })
                  this.setState({
                    titleInformations: item.messages,
                    lang: item.language
                  })
                } else {
                  let item = all_language.find((i) => {
                    return i.language === 'ENGLISH'
                  })
        
                  this.setState({
                    titleInformations: item.messages,
                    lang: item.language
                  })
                }
                /********************************获取系统语言end*******************************/
        
        if (this.props.location.search.indexOf('?') !== -1) {
            let first = this.props.location.search.split('?')[1]
            if (first.indexOf('email=') !== -1 && first.indexOf('&sessionId=') !== -1) {
                let second = first.split('&')
                let third = {}
                second.forEach(item => {
                    let temp = item.split('=')
                    third[temp[0]] = temp[1]
                })
                this.setState({
                    email: third.email,
                    session: third.sessionId
                })
            }
        }
        window.scrollTo(0, 0)
    }

    sign = () => {
        if (this.state.psd.replace(/\s+/g,'') === '') {
            this.setState({
                psdWarn: true
            })
        }
        if (this.state.rpsd.replace(/\s+/g,'') === '') {
            this.setState({
                rpsdWarn: true
            })
        }
        if (this.state.psd.replace(/\s+/g,'') === '' || this.state.rpsd.replace(/\s+/g,'') === '') {
            return
        }
        if (this.state.psd.replace(/\s+/g,'') !== this.state.rpsd.replace(/\s+/g,'')) {
            this.setState({
                psdWarn: true,
                rpsdWarn: true
            })
            message.warning(this.state.titleInformations.g)
            return
        }
        if (this.state.psd.replace(/\s+/g,'').length < 8) {
            message.warning(this.state.titleInformations.h)
            return
        }

        if (this.state.email === null || this.state.session === null) {
            return
        }

        this.setState({
            loading: true,
            timer: setInterval(this.resetDefault, 60000)
        })

        http.renewPassword({
            params: {
                password: this.state.psd,
                email: this.state.email,
                sessionId: this.state.session
            },
            callback: this.callback
        })
    }

    callback = (res) => {
        this.setState({
            loading: false
        })
        clearInterval(this.state.timer)
        if (res.data.code === 0) {
            this.setState({
                success: true
            })
        } else {
            message.warning(res.data.message)
        }
    }

    render() {
        let load = ''
        if (this.state.loading) {
            load = <Spin />
        } else {
            load = ''
        }
        return (
            <div className="dark">
                <div className="cont">
                    <div className="logo"></div>
                    <div className="box">
                        {this.state.success !== true ? <div className="in">
                            <h1>{this.state.titleInformations.a}</h1>
                            <h2>{this.state.titleInformations.b}</h2>
                            <div className={this.state.psdWarn ? 'input-item blue' : 'input-item'}>
                                <input onKeyUp={this.keyup} type="password" value={this.state.psd} onChange={this.handleChange.bind(this, 'psd')} maxLength="16" />
                            </div>
                            <h2>{this.state.titleInformations.c}</h2>
                            <div className={this.state.rpsdWarn ? 'input-item blue' : 'input-item'} style={{'marginBottom':'50px'}}>
                                <input onKeyUp={this.keyup} type="password" value={this.state.rpsd} onChange={this.handleChange.bind(this, 'rpsd')} maxLength="16" />
                            </div>
                            <button onClick={this.sign} disabled={this.state.loading}>{this.state.titleInformations.d}{load}</button>
                        </div> : <div className="in">
                            <h1>{this.state.titleInformations.e}</h1>
                            <p>{this.state.titleInformations.f}</p>
                        </div>}
                    </div>
                </div>
            </div>
        )
    }
}

export default Security