import axios from 'axios';
import { message } from 'antd'

const baseURL = 'https://api.safie.com.au'
// const baseURL = 'https://venue-api.ambl.co'
// const baseURL = 'https://api.safie.com.au'
// 创建axios实例
let service = axios.create({
    timeout: 60000, // 请求超时时间
    responseType: 'json',
    headers: {
        "Content-Type": "application/json"
    }
})

// 请求拦截
service.interceptors.request.use(     
    config => {        
        const token = window.sessionStorage.getItem("token-app");
        token && (config.headers.token = token);        
        return config;    
    },    
    error => {        
        return Promise.error(error);    
})

// 响应拦截器
service.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        return Promise.reject(error)
    }
)

const requestMethod = ['get', 'delete', 'post', 'put']

const $axios = {}

requestMethod.forEach(method => {
    $axios[method] = function(url, data = {}, cancelToken) {
        return new Promise((resolve, reject) => {
            if (method === 'get') {
                service[method](`${baseURL}${url}`, {cache: false})
                .then(response => {
                    packageRespon(response, resolve);
                }, err => {
                    packageRespon(err, resolve);
                })
                .catch((error) => {
                    packageRespon(error, resolve);
                })
            } else {
                service[method](baseURL + url, data, {cancelToken: cancelToken})
                .then(response => {
                    packageRespon(response, resolve);
                }, err => {
                    message.error('Error')
                    packageRespon(err, resolve);
                })
                .catch((error) => {
                    packageRespon(error, resolve);
                })
            }
        })
    }
})

function packageRespon(res, resolve) {
    resolve(res);
}

let lang = navigator.language || navigator.userLanguage
if (lang.indexOf('-') !== -1)
lang = lang.split('-')[0]

let text = ''
if (lang === 'ar')
    text = 'غير قادر على الاتصال بالخادم.'
else if (lang === 'de')
    text = 'Es kann keine Verbindung zum Server hergestellt werden.'
else if (lang === 'fr' || lang === 'fr-FR' || lang === 'fr-fr')
    text = 'Impossible de se connecter au serveur.'
else if (lang === 'ru')
    text = 'Не удается подключиться к серверу.'
else if (lang === 'it' || lang === 'it-IT' || lang === 'it-it')
    text = 'Impossibile connettersi al server.'
else if (lang === 'hi')
    text = 'सर्वर से कनेक्ट करने में अक्षम।'
else
    text = 'Unable to connect to the server.'


function packageCatch(err) {
    message.warning(err.message);
}


export default $axios