import Index from '../components/index';
import User from '../components/page/user';
/*import Reward from '../components/page/reward'
import Rewardadd from '../components/page/rewardadd'
import Rewardedit from '../components/page/rewardedit'
import Record from '../components/page/record'*/
import Login from '../components/login';
import AppDelete from '../components/app';
import Rescue from '../components/rescue';
import Security from '../components/security';
import Pin from '../components/pin';
import Map from '../components/map';
import Version from '../components/page/version';
import Versionadd from '../components/page/versionadd';
import Promo from '../components/page/promo';
import Promoadd from '../components/page/promoadd';
import Activate from '../components/page/activate';
import Term from '../components/term';
import Basic from '../components/page/basic';

let routes = [
    {
        path: '/login',
        component: Login,
        exact: true
    },
    {
        path: '/rescue/:id',
        component: Rescue,
        exact: true
    },
    {
        path: '/security',
        component: Security,
        exact: true
    },
    {
        path: '/pin',
        component: Pin,
        exact: true
    },
    {
        path: '/map',
        component: Map,
        exact: true
    },
    {
        path: '/term',
        component: Term,
        exact: true
    },
    {
        path: '/delete',
        component: AppDelete,
        exact: true
    },
    {
        path: '/',
        component: Index,
        exact: false,
        routes: [
            {
                path: '/user',
                component: User,
                exact: false
            },
            /*{
                path: '/reward',
                component: Reward,
                exact: false
            },
            {
                path: '/rewadd',
                component: Rewardadd,
                exact: false
            },
            {
                path: '/rewedit/:id',
                component: Rewardedit,
                exact: false
            },
            {
                path: '/record',
                component: Record,
                exact: false
            },*/
            {
                path: '/version',
                component: Version,
                exact: false
            },
            {
                path: '/veradd/:id',
                component: Versionadd,
                exact: false
            },
            {
                path: '/promo',
                component: Promo,
                exact: false
            },
            {
                path: '/promoadd',
                component: Promoadd,
                exact: false
            },
            {
                path: '/basic',
                component: Basic,
                exact: false
            },
            {
                path: '/activate',
                component: Activate,
                exact: false
            }
        ]
    }
]

export default routes