import React from 'react'
import { message, Table, Pagination, Modal, Upload, Button, DatePicker, Form, Input, Radio } from 'antd'
import { PlusOutlined, ArrowLeftOutlined, FileOutlined, CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import moment from 'moment';
import http from '../../api/request'
import format from '../../untils/format'
import axios from 'axios'
import ExcelTemplate from '../../assets/mb.xlsx'
const CancelToken = axios.CancelToken;
var source = CancelToken.source();
const { Column } = Table;

class Activate extends React.Component {
    constructor() {
        super()
        this.state = {
            loading: true,
            page: 1,
            cur: 2,
            list: [],
            totalRecord: 0,
            modalVisible: false,
            file: null,
            name: null,
            modal2Visible: false,
            date: null,
            dateWarn: false,
            expiDate: null,
            uploading: false,
            registeredCount: 0,
            unregisteredCount: 0
        }
    }

    formRef = React.createRef();

    // 开始请求
    componentDidMount () {
        this.getList()
        window.scrollTo(0, 0)
    }

    getList = () => {
        http.getActivateHistoryList({
            params: {
                page: this.state.page,
                size: 10
            },
            callback: this.callback,
            cancelToken: source.token
        })
    }

    // 请求回调
    callback = (res) => {
        if (res.data.code === 0) {
            res.data.data.list.forEach(item => {
                item.key = item.id
                item.createTime = format.timestampToTime(item.createTime)
                item.expirationTime = item.expirationTime === 0 ? 'Permanently Available' : format.timestampToDate(item.expirationTime)
            })
            this.setState({
                list: res.data.data.list,
                loading: false,
                totalRecord: res.data.data.totalRecord
            })
        } else if (res.data.code === 10001) {
            //message.warning(res.data.message)
            this.props.history.push('/login')
        } else if (res.data.code === 10002) {
            message.warning(res.data.message)
            this.props.history.push('/login')
        } else {
            message.warning(res.data.message)
        }
    }

    // 切换页码
    pageEvt = (page, pageSize) => {
        if (this.state.loading) {
            source.cancel('Operationcanceled');
            source = null
            source = CancelToken.source();
        }
        if (page === this.state.page)
            return
        this.setState({
            page: page,
            list: [],
            loading: true
        }, () => {
            window.scrollTo(0, 0)
            this.getList()
        })
    }

    goback = () => {
        this.props.history.go(-1)
    }

    download = () => {
        var blobURL = ExcelTemplate;
        // 创建隐藏<a>标签进行下载
        var tempLink = document.createElement("a");
        tempLink.style.display = "none";
        tempLink.href = blobURL;
        tempLink.setAttribute("download", `Activate Premium`);
        if (typeof tempLink.download === "undefined") {
        tempLink.setAttribute("target", "_blank");
        }
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
    }

    batch = () => {
        this.setState({
            modalVisible: true
        })
    }

    handleChange = (e) => {
        let name = e.file.name.split('.')
        if (name[name.length - 1] !== 'xlsx') {
            message.warning('Form error')
            return
        }
        // 先调用excel解析的接口
        let param = new FormData();
        param.append('file', e.file)
        this.setState({
            uploading: true
        })
        http.uploadExcelfile({
            params: param,
            callback: (res) => {
                this.setState({
                    uploading: false
                })
                if (res.data.code === 0) {
                    this.setState({
                        file: e.file,
                        name: e.file.name,
                        registeredCount: res.data.data.registeredCount,
                        unregisteredCount: res.data.data.unregisteredCount
                    })
                } else {
                    message.warning(res.data.message)
                }
            }
        })
    }

    remove = (e) => {
        this.setState({
            file: null,
            name: null
        })
    }

    confirm = () => {
        if (this.state.uploading)
            return
        if (!this.state.file) {
            message.warning('Please upload first')
            return
        }
        
        // 当前上传的弹窗关闭，打开输入信息的弹窗
        this.setState({
            modalVisible: false,
            modal2Visible: true,
            expiDate: null
        })
    }

    setModal2Visible(modal2Visible) {
        if (this.state.requesting)
            return
        this.setState({ 
            modal2Visible,
            file: null,
            name: null
         });
        this.formRef.current.resetFields()
    }

    setModalVisible(modalVisible) {
        this.setState({ modalVisible });
        this.setState({
            file: null,
            name: null
        })
    }

    // 选中了时间的事件
    dateEvt = (value, string) => {
        let time
        if (!value) {
            return
        } 
        time = (new Date(string)).getTime() / 1000
        this.setState({
            expiDate: time,
            dateWarn: false
        })
    }

    onFinish = (values) => {
        if (this.state.requesting)
            return
        if (values.date === 2 && this.state.expiDate === null) {
            this.setState({
                dateWarn: true
            })
            return
        }
        let data = {
            expirationDate: values.date === 1 ? 0 : this.state.expiDate,
            extraFreeDuration: values.extra
        }
        let param = new FormData();
        param.append('file', this.state.file)
        data.param = param
        this.setState({
            requesting: true,
            file: null,
            name: null
        })
        http.importActivateInfo({
            params: data,
            callback: this.imporCallback
        })
        this.formRef.current.resetFields()
        setTimeout(() => {
            if (this.state.requesting === true) {
                this.setState({
                    requesting: false
                })
            }
        }, 60000)
    }

    // 请求回调
    imporCallback = (res) => {
        this.setState({
            requesting: false,
            modal2Visible: false
        })
        if (res.data.code === 0) {
            this.getList()
        } else {
            message.warning(res.data.message)
        }
    }

    onFinishFailed = (errorInfo) => {
        if (errorInfo.values.date === 2 && this.state.expiDate === null) {
            this.setState({
                dateWarn: true
            })
        }
        // console.log('Failed:', errorInfo);
    }

    back = () => {
        if (this.state.requesting)
            return
        this.setState({
            modalVisible: true,
            modal2Visible: false,
            expiDate: null,
            date: null,
            dateWarn: false
        })
        this.formRef.current.resetFields()
    }

    disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().endOf('day') - 86400000;
    }

    render() {
        const layout = {
            labelCol: {
              span: 6,
            },
            wrapperCol: {
              span: 14,
            },
        }
        const tailLayout = {
            wrapperCol: {
              offset: 6,
              span: 14,
            },
        }

        const data = this.state.list

        const loading = this.state.loading

        const dateWarn = this.state.dateWarn

        const uploading = this.state.uploading

        const all = this.state.registeredCount + this.state.unregisteredCount
        const registeredCount = this.state.registeredCount
        const unregisteredCount = this.state.unregisteredCount

        let table = <Table dataSource={data} pagination={false}
                    onRow = {record => {
                        return {
                            onClick: event => {
                                // // console.log(event)
                            }
                        } 
                    }}
                    loading={loading}
                    >
                        <Column title="Import Date" dataIndex="createTime" key="id" />
                        <Column title="Number of Accounts Imported" dataIndex="userName" key="id" />
                        <Column title="Free Duration" dataIndex="extraFreeDuration" key="id"
                        render={(text, record) => (
                            <div>{text} {text > 1 ? 'days' : 'day'}</div>
                        )}/>
                        <Column title="Code Expiration Date" dataIndex="expirationTime" key="id" width="200px" />
                    </Table>

        const uploadButton = (
            <Upload
            listType="picture"
            customRequest={this.handleChange.bind(this)}
            showUploadList={false}
            className="uploader"
            >
                <div className="upload">
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Drag the file here, or Click to upload <br />Only Excel files can be uploaded</div>
                </div>
            </Upload>
            
        )

        const showExcel = (
            <div className="image-wrap">
                <div className="excl"></div>
                <h1>{this.state.name}</h1>
                <div onClick={this.remove.bind(this)} className="icon"><CloseCircleOutlined /></div>
            </div>
        )

        const uploaingMode = (
            <div className="image-wrap"><LoadingOutlined /></div>
        )

        let upload_area = ''

        if (!this.state.file) {
            if (uploading === false)
                upload_area = uploadButton
            else
                upload_area = uploaingMode
        }
        else
            upload_area = showExcel

        return (
            <div className="main">
                <div className="search clear">
                    <div class="back" onClick={this.goback}><ArrowLeftOutlined /> Back</div>
                    <div onClick={this.batch.bind(this)} className="create" style={{'float':'right'}}><button><PlusOutlined />Batch Activation</button></div>
                    <div onClick={this.download.bind(this)} className="create" style={{'float':'right'}}><button><FileOutlined />Download Template</button></div>
                </div>
                <div className="list">{table}</div>
                <div className="page">
                    {this.state.totalRecord === 0 ? '' : <Pagination showQuickJumper total={this.state.totalRecord} 
                    defaultPageSize={10} 
                    showSizeChanger={false}
                    onChange={this.pageEvt.bind(this)} />}
                </div>

                <Modal
                title="Import Activation List"
                centered
                visible={this.state.modalVisible}
                closable={true}
                maskClosable={false}
                cancelText={true}
                okText={true}
                width={600}
                onCancel={() => this.setModalVisible(false)}
                >
                    <div className="upload-area2">
                        {upload_area}
                    </div>
                    <div className="btn-confirm"><Button onClick={this.confirm.bind(this)} size="middle">Confirm</Button></div>
                </Modal>

                <Modal
                title="Import successfully!"
                centered
                visible={this.state.modal2Visible}
                closable={true}
                maskClosable={false}
                cancelText={true}
                okText={true}
                width={600}
                onCancel={() => this.setModal2Visible(false)}
                >
                    <div className="upload-more">
                        <h1>{all} accounts were successfully imported, of which {unregisteredCount} were unregistered accounts and {registeredCount} were registered accounts.</h1>
                        <Form
                        {...layout}
                        name="basic"
                        ref={this.formRef}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        >

                        <Form.Item
                            label="Extra Free Duration"
                            name="extra"
                            rules={[
                            {
                                required: true,
                                message: ''
                            },
                            ]}
                        >
                            <div className="extraInput"><Input maxLength={30} />
                            <div className="tag">days</div></div>
                        </Form.Item>

                        <Form.Item
                            label="Expiration Date"
                            name="date"
                            initialValue={1}
                            rules={[
                            {
                                required: true,
                                message: ''
                            },
                            ]}
                        >
                            <Radio.Group>
                                <div className="radioLines"><Radio value={1}>Permanently Available</Radio></div>
                                <div>
                                    <Radio value={2}></Radio>
                                    <DatePicker disabledDate={this.disabledDate} className={dateWarn === true ? 'warn-promo' : ''} onChange={this.dateEvt.bind(this)} />
                                </div>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item {...tailLayout} style={{'textAlign': 'center', 'marginTop': '50px'}}>
                            <Button onClick={this.back.bind(this)}>
                            Back
                            </Button>
                            <Button style={{'marginLeft': '20px'}} type="primary" htmlType="submit">
                            Create
                            </Button>
                        </Form.Item>
                        </Form>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default Activate
