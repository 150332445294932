import React from 'react'
import { message, Table, Pagination, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import http from '../../api/request'
import format from '../../untils/format'
import axios from 'axios'
const CancelToken = axios.CancelToken;
var source = CancelToken.source();
const { Column } = Table;

class Record extends React.Component {
    constructor() {
        super()
        this.state = {
            loading: true,
            page: 1,
            cur: 2,
            list: [],
            totalRecord: 0
        }
    }

    // 开始请求
    componentDidMount () {
        if (localStorage.getItem('type') && localStorage.getItem('type') === '1') {
            this.setState({
                cur: 1
            },() => {
                http.versionList({
                    params: {
                        page: this.state.page,
                        size: 10,
                        sendStatus: this.state.cur
                    },
                    callback: this.callback,
                    cancelToken: source.token
                })
                localStorage.removeItem('type')
            })
        } else {
            http.versionList({
                params: {
                    page: this.state.page,
                    size: 10,
                    sendStatus: this.state.cur
                },
                callback: this.callback,
                cancelToken: source.token
            })
        }
        
        window.scrollTo(0, 0)
    }

    // 请求回调
    callback = (res) => {
        if (res.data.code === 0) {
            res.data.data.list.forEach(item => {
                item.key = item.id
                item.updateTime = format.timestampToTime(item.updateTime)
            })
            this.setState({
                list: res.data.data.list,
                loading: false,
                totalRecord: res.data.data.totalRecord
            })
        } else if (res.data.code === 10001) {
            //message.warning(res.data.message)
            this.props.history.push('/login')
        } else if (res.data.code === 10002) {
            message.warning(res.data.message)
            this.props.history.push('/login')
        } else {
            message.warning(res.data.message)
        }
    }

    // 切换分类
    cut = (type) => {
        if (this.state.loading) {
            source.cancel('Operationcanceled');
            source = null
            source = CancelToken.source();
        }
        this.setState({
            cur: type,
            list: [],
            loading: true,
            totalRecord: 0
        }, () => {
            http.versionList({
                params: {
                    page: this.state.page,
                    size: 10,
                    sendStatus: this.state.cur
                },
                callback: this.callback,
                cancelToken: source.token
            })
        })
    }

    // 切换页码
    pageEvt = (page, pageSize) => {
        if (this.state.loading) {
            source.cancel('Operationcanceled');
            source = null
            source = CancelToken.source();
        }
        if (page === this.state.page)
            return
        this.setState({
            page: page,
            list: [],
            loading: true
        }, () => {
            window.scrollTo(0, 0)
            http.versionList({
                params: {
                    page: this.state.page,
                    size: 10,
                    sendStatus: this.state.cur
                },
                callback: this.callback,
                cancelToken: source.token
            })
        })
    }

    // 去新增页
    jump = () => {
        this.props.history.push(`/veradd/${this.state.cur}`)
    }

    render() {
        const data = this.state.list

        const loading = this.state.loading

        let table = <Table dataSource={data} pagination={false}
                    onRow = {record => {
                        return {
                            onClick: event => {
                                // // console.log(event)
                            }
                        } 
                    }}
                    loading={loading}
                    >
                        <Column title="Version" dataIndex="version" key="id" />
                        <Column title="Minimum Version" dataIndex="minVersion" key="id" />
                        <Column title="Update Time" dataIndex="updateTime" key="id" />
                        <Column title="Update Details" dataIndex="updateDetails" key="id"
                        render={(text, record) => (
                            <Space size="middle">
                                <div style={{'whiteSpace':'pre-wrap'}}>{text}</div>
                            </Space>
                        )} />
                    </Table>

        return (
            <div className="main">
                <div className="tab clear">
                    <div className={this.state.cur === 2 ? 'tit active' : 'tit'} onClick={this.cut.bind(this, 2)}>IOS App</div>
                    <div className={this.state.cur === 1 ? 'tit active' : 'tit'} onClick={this.cut.bind(this, 1)}>Android App</div>
                    <div className="create" style={{'float':'right'}}><button onClick={this.jump}><PlusOutlined />Upload Version Details</button></div>
                </div>
                <div className="list">{table}</div>
                <div className="page">
                    {this.state.totalRecord === 0 ? '' : <Pagination showQuickJumper total={this.state.totalRecord} 
                    defaultPageSize={10} 
                    showSizeChanger={false}
                    onChange={this.pageEvt.bind(this)} />}
                </div>
            </div>
        )
    }
}

export default Record