// import { render } from '@testing-library/react'
import React from 'react'
import {Component} from 'react'
import '../assets/css/index.scss'
import image from '../assets/i.png'
import { message } from 'antd'

class Map extends Component{
    constructor() {
        super()
        this.state = {
          lat: null,
          lon: null
        }
    }

    componentDidMount () {
        if (this.props.location.search.indexOf('?') !== -1) {
            let first = this.props.location.search.split('?')[1]
            if (first.indexOf('lat=') !== -1 && first.indexOf('&log=') !== -1) {
                let second = first.split('&')
                let third = {}
                second.forEach(item => {
                    let temp = item.split('=')
                    third[temp[0]] = temp[1]
                })
                this.loadMap(third.lat, third.log)
                this.setState({
                  lat: third.lat,
                  lon: third.log
                }, () => {// console.log(this.state.lat)
                })
                
            }
        }
        window.scrollTo(0, 0)
    }

    loadMap(lat, lng){
      var _doc=document.getElementsByTagName('head')[0];
      var script=document.createElement('script');
      script.setAttribute('type','text/javascript');
      script.setAttribute('src','https://maps.googleapis.com/maps/api/js?key=AIzaSyC40TIlTPqAP4dpavVrddyyTKNEsDilJT0&callback=initMap&force=lite');
      _doc.appendChild(script);
      var that = this
      script.onload=script.onreadystatechange=function(){
        if(!this.readyState||this.readyState=='loaded'||this.readyState=='complete'){
          that.initMap(lat, lng)
        }
        script.onload=script.onreadystatechange=null;
      }
    }

    initMap (lat, lng) {
        var options = {
          zoom: 16,
          center: new window.google.maps.LatLng(lat, lng),
          // mapTypeId: window.google.maps.MapTypeId.ROADMAP,
          scaleControl: false,
          mapTypeControl: false,
          zoomControl: false,
          streetViewControl: false,
          styles: [
            { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
            { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
            { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
            {
              featureType: "administrative.locality",
              elementType: "labels.text.fill",
              stylers: [{ color: "#d59563" }],
            },
            {
              featureType: "poi",
              elementType: "labels.text.fill",
              stylers: [{ color: "#d59563" }],
            },
            {
              featureType: "poi.park",
              elementType: "geometry",
              stylers: [{ color: "#263c3f" }],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.fill",
              stylers: [{ color: "#6b9a76" }],
            },
            {
              featureType: "road",
              elementType: "geometry",
              stylers: [{ color: "#38414e" }],
            },
            {
              featureType: "road",
              elementType: "geometry.stroke",
              stylers: [{ color: "#212a37" }],
            },
            {
              featureType: "road",
              elementType: "labels.text.fill",
              stylers: [{ color: "#9ca5b3" }],
            },
            {
              featureType: "road.highway",
              elementType: "geometry",
              stylers: [{ color: "#746855" }],
            },
            {
              featureType: "road.highway",
              elementType: "geometry.stroke",
              stylers: [{ color: "#1f2835" }],
            },
            {
              featureType: "road.highway",
              elementType: "labels.text.fill",
              stylers: [{ color: "#f3d19c" }],
            },
            {
              featureType: "transit",
              elementType: "geometry",
              stylers: [{ color: "#2f3948" }],
            },
            {
              featureType: "transit.station",
              elementType: "labels.text.fill",
              stylers: [{ color: "#d59563" }],
            },
            {
              featureType: "water",
              elementType: "geometry",
              stylers: [{ color: "#17263c" }],
            },
            {
              featureType: "water",
              elementType: "labels.text.fill",
              stylers: [{ color: "#515c6d" }],
            },
            {
              featureType: "water",
              elementType: "labels.text.stroke",
              stylers: [{ color: "#17263c" }],
            },
          ]
        }

        var map = new window.google.maps.Map(document.getElementById('map'), options);
        let places = []
        places.push(new window.google.maps.LatLng(lat, lng))
        for (var i = 0; i < places.length; i++) {
          var marker = new window.google.maps.Marker({
            position: places[i],
            map: map,
            title: 'Place number ' + i,
            icon: image
          })
        }
    }

    openList = () =>  {
      this.setState({
        showAlert: true
      })
    }

    cancelOpenapp = () =>  {
      this.setState({
        showAlert: false
      })
    }

    openApp = () => {
      let browser = {
          versions: function () {
              let u = navigator.userAgent,
                  app = navigator.appVersion;
              return {
                  trident: u.indexOf('Trident') > -1, /*IE内核*/
                  presto: u.indexOf('Presto') > -1, /*opera内核*/
                  webKit: u.indexOf('AppleWebKit') > -1, /*苹果、谷歌内核*/
                  gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, /*火狐内核*/
                  mobile: !!u.match(/AppleWebKit.*Mobile.*/), /*是否为移动终端*/
                  ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), /*ios终端*/
                  // android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, /*android终端或者uc浏览器*/
                  android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1,
                  iPhone: u.indexOf('iPhone') > -1, /*是否为iPhone或者QQHD浏览器*/
                  iPad: u.indexOf('iPad') > -1, /*是否iPad*/
                  webApp: u.indexOf('Safari') == -1, /*是否web应该程序，没有头部与底部*/
                  souyue: u.indexOf('souyue') > -1,
                  superapp: u.indexOf('superapp') > -1,
                  weixin: u.toLowerCase().indexOf('micromessenger') > -1,
                  Safari: u.indexOf('Safari') > -1,
                  uc: u.indexOf('Linux') > -1
              };

          }(),
          language: (navigator.browserLanguage || navigator.language).toLowerCase()
      };
      if (browser.versions.ios) {
          window.location.href = `https://www.google.com/maps/dir/?api=1&destination=${this.state.lat},${this.state.lon}&travelmode=driving`
      }
      else if (browser.versions.android) {
          window.location.href = `https://www.google.com/maps/dir/?api=1&destination=${this.state.lat},${this.state.lon}&travelmode=driving`
      }
    }

    openApp3 = () => {
      window.location.href = `http://maps.apple.com/?daddr=${this.state.lat},${this.state.lon}&dirflg=d`
    }

    render() {
        let type = ""
        let browser = {
          versions: function () {
            let u = navigator.userAgent,
                app = navigator.appVersion;
            return {
                trident: u.indexOf('Trident') > -1, /*IE内核*/
                presto: u.indexOf('Presto') > -1, /*opera内核*/
                webKit: u.indexOf('AppleWebKit') > -1, /*苹果、谷歌内核*/
                gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, /*火狐内核*/
                mobile: !!u.match(/AppleWebKit.*Mobile.*/), /*是否为移动终端*/
                ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), /*ios终端*/
                // android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, /*android终端或者uc浏览器*/
                android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1,
                iPhone: u.indexOf('iPhone') > -1, /*是否为iPhone或者QQHD浏览器*/
                iPad: u.indexOf('iPad') > -1, /*是否iPad*/
                webApp: u.indexOf('Safari') == -1, /*是否web应该程序，没有头部与底部*/
                souyue: u.indexOf('souyue') > -1,
                superapp: u.indexOf('superapp') > -1,
                weixin: u.toLowerCase().indexOf('micromessenger') > -1,
                Safari: u.indexOf('Safari') > -1,
                uc: u.indexOf('Linux') > -1
            };

        }(),
        language: (navigator.browserLanguage || navigator.language).toLowerCase()
        };
        if (browser.versions.ios) {
            type = 'ios'
        }
        else if (browser.versions.android) {
            type = 'android'
        }

        let alert = ''
        if (this.state.showAlert === true) {
          alert = <div className="al-wp">
            <div className="al-bg">
              <div className="line" onClick={this.openApp3} style={{'display': type === 'ios' ? 'block' : 'none'}}>Apple Maps</div>
              <div className="line" onClick={this.openApp} style={{'marginBottom': '.2rem'}}>Google Maps</div>
              <div className="line" onClick={this.cancelOpenapp}>Cancel</div>
            </div>
          </div>
        }
        return (
            <div className="recude2">
                <div className="logo"></div>
                <div className="wrap">
                    <div className="map" id="map"></div>
                    <div onClick={this.openList} className="jump"></div>
                </div>
                {alert}
            </div>
        )
    }
}

export default Map