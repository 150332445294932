import React from 'react'
import {Component} from 'react'
import { message, Spin, Modal, Button } from 'antd'
import '../assets/css/index.scss'
import http from '../api/appOperate'
import axios from 'axios';

var all_language = [
    {
      language: 'ar',
      messages:
        {
          a: 'Login to Safie',
          b: 'Email',
          c: 'Password',
          d: 'Login',
          e: 'Delete Safie’s account',
          f: 'You can delete your Safie APP account from the web',
          g: 'Delete account',
          h: 'Please enter a password of 8-16 character',
          i: 'Incorrect email format.',
          j: 'Or log in with',
          k: 'Thank you for your continued support and trust in our services.',
          l: 'Please note that once your account is deleted,',
          m: 'you will lose all data and information on the platform,',
          n: 'including but not limited to your personal information, contacts, panic records, etc',
          o: 'Delete account',
          p: 'Deleting an account will remove all data on this account and cannot be undone. Are you sure you want to continue?'
        }
    },
    {
      language: 'de',
      messages:
        {
          a: 'Login to Safie',
          b: 'Email',
          c: 'Password',
          d: 'Login',
          e: 'Delete Safie’s account',
          f: 'You can delete your Safie APP account from the web',
          g: 'Delete account',
          h: 'Please enter a password of 8-16 character',
          i: 'Incorrect email format.',
          j: 'Or log in with',
          k: 'Thank you for your continued support and trust in our services.',
          l: 'Please note that once your account is deleted,',
          m: 'you will lose all data and information on the platform,',
          n: 'including but not limited to your personal information, contacts, panic records, etc',
          o: 'Delete account',
          p: 'Deleting an account will remove all data on this account and cannot be undone. Are you sure you want to continue?'
        }
    },
    {
      language: 'fr',
      messages:
        {
          a: 'Login to Safie',
          b: 'Email',
          c: 'Password',
          d: 'Login',
          e: 'Delete Safie’s account',
          f: 'You can delete your Safie APP account from the web',
          g: 'Delete account',
          h: 'Please enter a password of 8-16 character',
          i: 'Incorrect email format.',
          j: 'Or log in with',
          k: 'Thank you for your continued support and trust in our services.',
          l: 'Please note that once your account is deleted,',
          m: 'you will lose all data and information on the platform,',
          n: 'including but not limited to your personal information, contacts, panic records, etc',
          o: 'Delete account',
          p: 'Deleting an account will remove all data on this account and cannot be undone. Are you sure you want to continue?'
        }
    },
    {
        language: 'fr-fr',
        messages:
          {
            a: 'Login to Safie',
            b: 'Email',
            c: 'Password',
            d: 'Login',
            e: 'Delete Safie’s account',
            f: 'You can delete your Safie APP account from the web',
            g: 'Delete account',
            h: 'Please enter a password of 8-16 character',
            i: 'Incorrect email format.',
            j: 'Or log in with',
            k: 'Thank you for your continued support and trust in our services.',
            l: 'Please note that once your account is deleted,',
            m: 'you will lose all data and information on the platform,',
            n: 'including but not limited to your personal information, contacts, panic records, etc',
            o: 'Delete account',
            p: 'Deleting an account will remove all data on this account and cannot be undone. Are you sure you want to continue?'
          }
      },

      {
        language: 'fr-FR',
        messages:
          {
            a: 'Login to Safie',
            b: 'Email',
            c: 'Password',
            d: 'Login',
            e: 'Delete Safie’s account',
            f: 'You can delete your Safie APP account from the web',
            g: 'Delete account',
            h: 'Please enter a password of 8-16 character',
            i: 'Incorrect email format.',
            j: 'Or log in with',
            k: 'Thank you for your continued support and trust in our services.',
            l: 'Please note that once your account is deleted,',
            m: 'you will lose all data and information on the platform,',
            n: 'including but not limited to your personal information, contacts, panic records, etc',
            o: 'Delete account',
            p: 'Deleting an account will remove all data on this account and cannot be undone. Are you sure you want to continue?'
          }
      },
  
    {
      language: 'ru',
      messages:
        {
          a: 'Login to Safie',
          b: 'Email',
          c: 'Password',
          d: 'Login',
          e: 'Delete Safie’s account',
          f: 'You can delete your Safie APP account from the web',
          g: 'Delete account',
          h: 'Please enter a password of 8-16 character',
          i: 'Incorrect email format.',
          j: 'Or log in with',
          k: 'Thank you for your continued support and trust in our services.',
          l: 'Please note that once your account is deleted,',
          m: 'you will lose all data and information on the platform,',
          n: 'including but not limited to your personal information, contacts, panic records, etc',
          o: 'Delete account',
          p: 'Deleting an account will remove all data on this account and cannot be undone. Are you sure you want to continue?'
        }
    },
    {
      language: 'it',
      messages:
        {
          a: 'Login to Safie',
          b: 'Email',
          c: 'Password',
          d: 'Login',
          e: 'Delete Safie’s account',
          f: 'You can delete your Safie APP account from the web',
          g: 'Delete account',
          h: 'Please enter a password of 8-16 character',
          i: 'Incorrect email format.',
          j: 'Or log in with',
          k: 'Thank you for your continued support and trust in our services.',
          l: 'Please note that once your account is deleted,',
          m: 'you will lose all data and information on the platform,',
          n: 'including but not limited to your personal information, contacts, panic records, etc',
          o: 'Delete account',
          p: 'Deleting an account will remove all data on this account and cannot be undone. Are you sure you want to continue?'
        }
    },
    {
        language: 'it-it',
        messages:
          {
            a: 'Login to Safie',
            b: 'Email',
            c: 'Password',
            d: 'Login',
            e: 'Delete Safie’s account',
            f: 'You can delete your Safie APP account from the web',
            g: 'Delete account',
            h: 'Please enter a password of 8-16 character',
            i: 'Incorrect email format.',
            j: 'Or log in with',
            k: 'Thank you for your continued support and trust in our services.',
            l: 'Please note that once your account is deleted,',
            m: 'you will lose all data and information on the platform,',
            n: 'including but not limited to your personal information, contacts, panic records, etc',
            o: 'Delete account',
            p: 'Deleting an account will remove all data on this account and cannot be undone. Are you sure you want to continue?'
          }
      },

      {
        language: 'it-IT',
        messages:
          {
            a: 'Login to Safie',
            b: 'Email',
            c: 'Password',
            d: 'Login',
            e: 'Delete Safie’s account',
            f: 'You can delete your Safie APP account from the web',
            g: 'Delete account',
            h: 'Please enter a password of 8-16 character',
            i: 'Incorrect email format.',
            j: 'Or log in with',
            k: 'Thank you for your continued support and trust in our services.',
            l: 'Please note that once your account is deleted,',
            m: 'you will lose all data and information on the platform,',
            n: 'including but not limited to your personal information, contacts, panic records, etc',
            o: 'Delete account',
            p: 'Deleting an account will remove all data on this account and cannot be undone. Are you sure you want to continue?'
          }
      },
  
  
    {
      language: 'hi',
      messages:
        {
          a: 'Login to Safie',
          b: 'Email',
          c: 'Password',
          d: 'Login',
          e: 'Delete Safie’s account',
          f: 'You can delete your Safie APP account from the web',
          g: 'Delete account',
          h: 'Please enter a password of 8-16 character',
          i: 'Incorrect email format.',
          j: 'Or log in with',
          k: 'Thank you for your continued support and trust in our services.',
          l: 'Please note that once your account is deleted,',
          m: 'you will lose all data and information on the platform,',
          n: 'including but not limited to your personal information, contacts, panic records, etc',
          o: 'Delete account',
          p: 'Deleting an account will remove all data on this account and cannot be undone. Are you sure you want to continue?'
        }
    },
    {
      language: 'ENGLISH',
      messages:
        {
          a: 'Login to Safie',
          b: 'Email',
          c: 'Password',
          d: 'Login',
          e: 'Delete Safie’s account',
          f: 'You can delete your Safie APP account from the web',
          g: 'Delete account',
          h: 'Please enter a password of 8-16 character',
          i: 'Incorrect email format.',
          j: 'Or log in with',
          k: 'Thank you for your continued support and trust in our services.',
          l: 'Please note that once your account is deleted,',
          m: 'you will lose all data and information on the platform,',
          n: 'including but not limited to your personal information, contacts, panic records, etc',
          o: 'Delete account',
          p: 'Deleting an account will remove all data on this account and cannot be undone. Are you sure you want to continue?'
        }
    }
  
]

  
class AppDelete extends Component{
    constructor() {
        super()
        this.state = {
            email: '',
            emailWarn: false,
            psd: '',
            psdWarn: false,
            loading: false,
            // timer: null,

            lang: '',
            /********************************获取系统语言start*******************************/
            titleInformations: {
              a: '',
              b: '',
              c: '',
              d: '',
              e: '',
              f: '',
              g: '',
              h: '',
              i: '',
              j: '',
              k: '',
              l: '',
              m: '',
              n: '',
              o: '',
              p: ''
            },
            /********************************获取系统语言end*******************************/
            step: 1,
            modalVisible: false,
            deleting: false,
            id: '',
        }
    }


    handleChange = (key, e) => {
        if (key === 'email') {
            let temp = e.target.value
            //temp = temp.replace(/[\W]/g,'')
            this.setState({
                email: temp
            }, () => {
                if (this.state.email.replace(/\s+/g,'') !== '') {
                    this.setState({
                        emailWarn: false
                    })
                }
            })
        } else if (key === 'psd') {
            this.setState({
                psd: e.target.value
            }, () => {
                if (this.state.psd.replace(/\s+/g,'') !== '') {
                    this.setState({
                        psdWarn: false
                    })
                }
            })
        }
    }

    handleBlur = (key, e) => {
      if (key === 'email') {
          let temp = e.target.value
          let reg = new RegExp(
            /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
            "g"
          );
          this.setState({
              email: temp
          }, () => {
              if (!reg.test(this.state.email)) {
                  this.setState({
                      emailWarn: true
                  })
              }
          })
      }
  }

    keyup = (e) => {
        if (e.keyCode === 13) {
            this.sign()
        }
    }

    resetDefault = () => {
        this.setState({
            loading: false
        })
    }

    componentDidMount () {
      /********************************获取系统语言start*******************************/
      let lang = navigator.language || navigator.userLanguage
      if (lang.indexOf('-') !== -1)
        lang = lang.split('-')[0]

      // console.log('打印语言')
      // console.log(lang)
      if (lang === 'ar' || lang === 'de' || lang === 'fr' || lang === 'ru' || lang === 'it' || lang === 'hi' || lang === 'fr-FR' || lang === 'it-IT' || lang === 'fr-fr' || lang === 'it-it') {
        let item = all_language.find((i) => {
          return i.language === lang
        })
        this.setState({
          titleInformations: item.messages,
          lang: item.language
        })
      } else {
        let item = all_language.find((i) => {
          return i.language === 'ENGLISH'
        })

        this.setState({
          titleInformations: item.messages,
          lang: item.language
        })
      }
      var that = this
      /********************************获取系统语言end*******************************/
      window.onload = function () {
        window.google.accounts.id.cancel();
        window.google.accounts.id.initialize({
          client_id: "721317702608-5mst6hu53hvrb6gscku4o167ee2v3bmi.apps.googleusercontent.com",
          callback: (e) => {
            console.log(e)
            const jwtParts = e.credential.split(".");
            const header = JSON.parse(atob(jwtParts[0]));
            const payload = JSON.parse(atob(jwtParts[1]));
            console.log(header);
            console.log(payload);
            http.loginByThirdApi({
              params: {
                "openId": payload.sub,
                "signType": 3,
                "webLogin": true
              },
              callback: (res) => {
                if (!res) {
                  return
                }
                const data = res.data
                if (!data)
                    return
                if (data.code === 0 && data.data && data.data.users && data.data.users.id) {
                    that.setState({
                      id: data.data.users.id,
                      step: 3,
                    })

                    window.sessionStorage.setItem("token-app", data.data.webToken)
                } else {
                  message.warning(data.message)
                }
              }
            })
          },
        });
        window.google.accounts.id.renderButton(
          document.getElementById("buttonDiv"),
          { theme: "outline", size: "large" }  // customization attributes
        );
        window.google.accounts.id.prompt(); // also display the One Tap dialog
      }
    }
    
    sign = () => {
        let reg = new RegExp(
          /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
          "g"
        );
        const boo = reg.test(this.state.email)
        console.log(boo)
        if (boo === false) {
          console.log(888)
          message.warning(this.state.titleInformations.i)
          this.setState({
              emailWarn: true
          })
        }
        if (this.state.psd.replace(/\s+/g,'').length < 8) {
            message.warning(this.state.titleInformations.h)
            this.setState({
                psdWarn: true
            })
        }
        if (boo === false || this.state.psd.replace(/\s+/g,'').length < 8) {
          return
        }

        this.setState({
            loading: true,
        })
        http.loginApi({
            params: {
              "email": this.state.email,
              "pwd": this.state.psd,
              "webLogin": true
            },
            callback: this.callback
        })
    }

    callback = (res) => {
        this.setState({
            loading: false
        })
        if (res.data.code === 0) {
          this.setState({
            // id: res.data.data.contacts[0].id,
            id: res.data.data.id,
            step: 3,
            loading: false
          })
          window.sessionStorage.setItem("token-app", res.data.data.webToken)
        } else {
          message.warning(res.data.message)
        }
    }

    toNext = (to) => {
      this.setState({
        step: to
      })
    }

    openPop = () =>{
      this.setModalVisible(true)
    }

    setModalVisible(modalVisible) {
      this.setState({ modalVisible });
    }

    confirmDelete = () => {
      if (this.state.deleting)
        return
      this.setState({ deleting: true });
      http.deleteApi({
        params: {
          id: this.state.id
        },
        callback: (res) => {
          if (!res || !res.data) {
            this.setState({ deleting: false });
             return
          }
          if (res.data.code === 0) {
            this.toNext(1)
            this.setState({ deleting: false, modalVisible: false });
            message.success("Your account has been successfully deleted.")
          } else {
            this.setState({ deleting: false, modalVisible: false });
            message.warning(res.data.message)
          }
        }
      })
    }
    render() {
        let load = ''
        if (this.state.loading) {
            load = <Spin />
        } else {
            load = ''
        }
        return (
            <div className="dark">
              <div className='outer' style={{width: '100%'}}>
                {this.state.step === 1 ? <div className="cont">
                    <div className="logo"></div>
                    <div className="box">
                      <div className="in">
                            <h1 className='align-center smaller-margin-bottom'>{this.state.titleInformations.e}</h1>
                            <h3>{this.state.titleInformations.f}</h3>
                            <button onClick={() => {
                              this.toNext(2)
                            }}>
                              {this.state.titleInformations.g}
                            </button>
                        </div>
                    </div>
                </div> : ''}
                <div className={this.state.step === 2? "cont" : "cont hidden"}>
                    <div className="logo"></div>
                    <div className="box">
                      <div className="in">
                            <h1 className='align-center'>{this.state.titleInformations.a}</h1>
                            <h2>{this.state.titleInformations.b}</h2>
                            <div className={this.state.emailWarn ? 'input-item blue' : 'input-item'}>
                                <input onKeyUp={this.keyup} type="text" value={this.state.email} 
                                onChange={this.handleChange.bind(this, 'email')}
                                onBlur={this.handleBlur.bind(this, 'email')}
                                placeholder='Please enter email' />
                            </div>
                            <h2>{this.state.titleInformations.c}</h2>
                            <div className={this.state.psdWarn ? 'input-item blue' : 'input-item'} style={{'marginBottom':'50px'}}>
                                <input onKeyUp={this.keyup} type="password" value={this.state.psd} 
                                onChange={this.handleChange.bind(this, 'psd')} maxLength="16"
                                placeholder='Please enter a password of 8-16 digits' />
                            </div>
                            <button disabled={this.state.email.replace(/\s+/g,'') === '' || this.state.psd.replace(/\s+/g,'') === '' || this.state.loading}
                            className={this.state.email.replace(/\s+/g,'') === '' || this.state.psd.replace(/\s+/g,'') === '' ? 'disabled-button mar-btm' : 'mar-btm'}
                            onClick={this.sign}>{this.state.titleInformations.d}{load}</button>
                            <div className='other-method clear'>
                              <div className='line1'></div>
                              <div className='txt'>{this.state.titleInformations.j}</div>
                              <div className='line2'></div>
                            </div>
                            <div className='google-login'>
                              <div id="buttonDiv"></div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.step === 3 ? <div className="cont">
                    <div className="logo"></div>
                    <div className="box">
                      <div className="in">
                            <h1 className='align-center smaller-margin-bottom'>{this.state.titleInformations.e}</h1>
                            <div className='tip-off mar-btm-30'>{this.state.titleInformations.k}</div>
                            <div className='tip-off'>{this.state.titleInformations.l}</div>
                            <div className='tip-off'>{this.state.titleInformations.m}</div>
                            <div className='tip-off mar-btm-58'>{this.state.titleInformations.n}</div>
                            <button onClick={() => {
                              this.openPop()
                            }}>
                              {this.state.titleInformations.o}
                            </button>
                        </div>
                    </div>
                </div> : ''}
              </div>
              <Modal
                title={this.state.titleInformations.o}
                centered
                visible={this.state.modalVisible}
                closable={true}
                maskClosable={false}
                cancelText={true}
                okText={true}
                width={432}
                onCancel={() => this.setModalVisible(false)}
                className='delete-wrap'
                >
                    <h1>{this.state.titleInformations.o}</h1>
                    <div className='txt'>{this.state.titleInformations.p}</div>
                    <div className='clear btns'>
                      <div className="btn-confirm">
                        <Button onClick={() => {
                          this.confirmDelete()
                        }} disabled={this.state.deleting}>Confirm {this.state.deleting ? <Spin /> : ''}</Button>
                      </div>
                      <div className="btn-cancel">
                        <Button disabled={this.state.deleting} onClick={() => this.setModalVisible(false)}>Cancel</Button>
                      </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default AppDelete