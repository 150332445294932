// import { render } from '@testing-library/react'
import React from 'react'
import {Component} from 'react'
import '../assets/css/index.scss'

class Term extends Component{
    constructor() {
        super()
        this.state = {}
    }


    componentDidMount () {
        window.scrollTo(0, 0)
    }


    render() {
        return (
           <div className="term">
                <div>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt', 'textAlign':'center'}}><a name="_Hlk55217643"></a><a
                name="_Hlk55243913"><span style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontWeight':'bold'}}>Privacy Policy
                </span><br /><span style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontWeight':'bold'}}>&#xa0;</span></a></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'-aw-bookmark-end':'_Hlk55217643'}}></span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Safie App
                Pty Ltd</span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>(ACN </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>641 890 815</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>)</span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>
            </span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>(</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>“</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontWeight':'bold'}}>we</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>” and “</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontWeight':'bold'}}>us</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>”</span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>)
            </span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>is subject to </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Australian Privacy Principles (APPs) under the Privacy Act
                1988 (Cth)</span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> (“</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontWeight':'bold'}}>Privacy Act</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>”)</span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>.
            </span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>The APPs regulate how personal information is handled
            </span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>by </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>us, including when you visit our website (“</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontWeight':'bold'}}>Website</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>”) or use the Safie phone app (“</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontWeight':'bold'}}>App</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>”)</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>.</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>This</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> Privacy Policy</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>applies to personal information collected and/or held by
            </span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>us</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>.</span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> 
                P</span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>ersonal information </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>means </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>information or an opinion about an identified individual, or
                an individual who is reasonably identifiable. </span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>
            </span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'-aw-bookmark-end':'_Hlk55243913'}}></span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontWeight':'bold'}}>What personal information do we
                collect?</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>The types of information that we collect and hold about you
                could include:</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 36pt', 'textIndent':'-18pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>•</span><span
                style={{'font':'7.0pt Times New Roman'}}>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>information</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> about your identity</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> such as your name, email address </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>and </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>telephone numbers;</span></p>
        <ul type="disc" style={{'margin':'0pt', 'paddingLeft':'0pt'}}>
            <li
                style={{'fontFamily':'serif', 'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 28.06pt', 'paddingLeft':'7.94pt', 'textIndent':'0pt'}}>
                <span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>w</span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>hen you visit our </span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Website</span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> </span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>or use our </span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>App</span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> — your location information, IP address, mobile device
                    and network information, and any third-party sites you access</span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>;</span></li>
        </ul>
        <p style={{'fontSize':'11pt','lineHeight':'108%', 'margin':'0pt 0pt 8pt 36pt', 'textIndent':'-18pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>•</span><span
                style={{'font':'7.0pt Times New Roman'}}>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>other personal information, such as details of your
                interactions with us.</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>If you press the “</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>PANIC</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>” button on the App, the App obtains the current latitude
                and longitude of your mobile device, and then shares that information with us.  We use the latitude and
                longitude to determine your address</span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>, using
                Google Maps</span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>.  </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Your</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> address information is then provided to your chosen
                emergency contacts.  The App does not collect or supply </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>any </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>location information unless you press the “</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>PANIC</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>” button.</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Not all information requested, collected, and processed by
                us is personal </span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>information</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> as it does not identify you as a specific </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>natural </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>person. This will include </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>most</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> of </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>the</span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>
                content you provide to us with the intention of sharing it with other users.  Such information is not
                covered by this </span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Privacy </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Policy</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>. </span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontStyle':'italic', 'textDecoration':'underline'}}>When the law
                authorises or requires us to collect information</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>We may collect information about you because we are required
                or authorised by law to collect it. This includes company and tax law, which require us to collect
                personal information.</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontStyle':'italic', 'textDecoration':'underline'}}>What
            </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontStyle':'italic', 'textDecoration':'underline'}}>information do
                we collect when you </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontStyle':'italic', 'textDecoration':'underline'}}>our Website or
                use </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontStyle':'italic', 'textDecoration':'underline'}}>the
                App</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontStyle':'italic', 'textDecoration':'underline'}}>?</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>When you </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>visit our</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Website or </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>use the </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>App</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>, we may collect information about your location or
                activity.  </span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Some of this information is collected using cookies.
            </span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> See </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>the section “</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Use of cookies</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>” below</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>.</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontWeight':'bold'}}>How do we collect your personal
                information?</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><a name="_Hlk55294653"><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontStyle':'italic', 'textDecoration':'underline'}}>Collection
                    from you</span></a></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'-aw-bookmark-end':'_Hlk55294653'}}></span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>We
                collect personal information and process such information when you:</span></p>
        <ul type="disc" style={{'margin':'0pt', 'paddingLeft':'0pt'}}>
            <li
                style={{'fontFamily':'serif', 'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 28.06pt', 'paddingLeft':'7.94pt', 'textIndent':'0pt'}}>
                <span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>subscribe as a user of</span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> </span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>the </span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>App</span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>;</span></li>
            <li
                style={{'fontFamily':'serif', 'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 28.06pt', 'paddingLeft':'7.94pt', 'textIndent':'0pt'}}>
                <span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>use the App;</span></li>
            <li
                style={{'fontFamily':'serif', 'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 28.06pt', 'paddingLeft':'7.94pt', 'textIndent':'0pt'}}>
                <span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>access </span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>our </span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Website</span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>; and</span></li>
            <li
                style={{'fontFamily':'serif', 'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt 28.06pt', 'paddingLeft':'7.94pt', 'textIndent':'0pt'}}>
                <span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>voluntarily provide us with feedback via
                    email</span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>.</span></li>
        </ul>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><a name="_Hlk55294717"><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>We </span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>may also </span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>collect personal information when we communicate with
                    you via</span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> </span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>email</span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> </span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>or SMS</span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>. </span></a></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>We will try to collect personal</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>information directly from you unless </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>it is</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> unreasonable or</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>impracticable. </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>For this reason, </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>it is</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> important that you keep</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>your contact details up</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>to</span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>
            </span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>date.</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'-aw-bookmark-end':'_Hlk55294717'}}></span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontStyle':'italic', 'textDecoration':'underline'}}>Collection
                from other sources</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>We may collect information about you that is publicly
                available (for example from public registers or social media) or made available by third parties.  For
                instance, we do this where:</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 36pt', 'textIndent':'-18pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>•</span><span
                style={{'font':'7.0pt Times New Roman'}}>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>we need information from third parties about an application
                you make through us;</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 36pt', 'textIndent':'-18pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>•</span><span
                style={{'font':'7.0pt Times New Roman'}}>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>we need information for fraud prevention purposes;</span>
        </p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 36pt', 'textIndent':'-18pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>•</span><span
                style={{'font':'7.0pt Times New Roman'}}>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>you have consented to third parties sharing it with
                us;</span></p>
        <p style={{'fontSize':'11pt','lineHeight':'108%', 'margin':'0pt 0pt 8pt 36pt', 'textIndent':'-18pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>•</span><span
                style={{'font':'7.0pt Times New Roman'}}>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>at your request, we exchange information with your legal or
                financial advisers or other representatives.</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>When you press the “PANIC” button, the App obtains the
            </span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>current latitude and longitude of your mobile
                device, and then shares that information with us.  We use </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>the</span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>
                latitude and longitude to determine your address</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>, using Google Maps.</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontWeight':'bold'}}>How do we use your personal
                information?</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontStyle':'italic', 'textDecoration':'underline'}}>Our use of
                your personal information</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>We use your information to:</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 36pt', 'textIndent':'-18pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>•</span><span
                style={{'font':'7.0pt Times New Roman'}}>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>to provide the service or product you have requested;</span>
        </p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 36pt', 'textIndent':'-18pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>•</span><span
                style={{'font':'7.0pt Times New Roman'}}>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>to answer your requests and complaints;</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 36pt', 'textIndent':'-18pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>•</span><span
                style={{'font':'7.0pt Times New Roman'}}>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>to identify opportunities to improve our service to
                you;</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 36pt', 'textIndent':'-18pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>•</span><span
                style={{'font':'7.0pt Times New Roman'}}>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>to prevent, detect or investigate any fraud or crime, or any
                suspected fraud or crime;</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 36pt', 'textIndent':'-18pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>•</span><span
                style={{'font':'7.0pt Times New Roman'}}>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>to comply with law, regulation or codes binding us;
                and</span></p>
        <p style={{'fontSize':'11pt','lineHeight':'108%', 'margin':'0pt 0pt 8pt 36pt', 'textIndent':'-18pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>•</span><span
                style={{'font':'7.0pt Times New Roman'}}>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>for any purpose for which you have given your
                consent.</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>If you press the “PANIC” button on the App, the App obtains
                the current latitude and longitude of your mobile device, and then shares that information with us.  We
                use the latitude and longitude to determine your address, using Google Maps.  Your address information
                is then provided to your chosen emergency contacts.  The App does not collect or supply any location
                information unless you press the “PANIC” button.</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontWeight':'bold'}}>Who do we share information with?</span>
        </p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>We may share your information with other organisations
                consistent with the purposes for which we use and process your information as described above. This
                includes with the entities described below.</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontStyle':'italic', 'textDecoration':'underline'}}>Our
                third-party service providers</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>We may disclose your </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>personal information</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> to third</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>-</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>part</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>y service providers</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>who</span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>
                help us run </span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>the </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>App</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>, some of whom are located outside Australia. </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> Our third-party service providers are bound by contract to
                only use your personal information on our behalf, under our instructions.</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>These third</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>-</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>parties </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>service providers </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>include:</span></p>
        <ul type="disc" style={{'margin':'0pt', 'paddingLeft':'0pt'}}>
            <li
                style={{'fontFamily':'serif', 'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 28.06pt', 'paddingLeft':'7.94pt', 'textIndent':'0pt'}}>
                <span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Google LLC., for the purposes of determining your
                    address</span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> using Google Maps</span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>;</span></li>
            <li
                style={{'fontFamily':'serif', 'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 28.06pt', 'paddingLeft':'7.94pt', 'textIndent':'0pt'}}>
                <span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>website</span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> hosting, storage, networking and related
                    providers;</span></li>
            <li
                style={{'fontFamily':'serif', 'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 28.06pt', 'paddingLeft':'7.94pt', 'textIndent':'0pt'}}>
                <span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>p</span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>ayment and banking providers;</span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> and</span></li>
            <li
                style={{'fontFamily':'serif', 'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt 28.06pt', 'paddingLeft':'7.94pt', 'textIndent':'0pt'}}>
                <span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>s</span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>ecurity providers.</span></li>
        </ul>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontStyle':'italic', 'textDecoration':'underline'}}>Other
                disclosures and transfers</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>We may also disclose your </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>personal information</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> to </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>third parties</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> for the following purposes:</span></p>
        <ul type="disc" style={{'margin':'0pt', 'paddingLeft':'0pt'}}>
            <li
                style={{'fontFamily':'serif', 'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 28.06pt', 'paddingLeft':'7.94pt', 'textIndent':'0pt'}}>
                <span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>to provide the service or product you have
                    requested;</span></li>
            <li
                style={{'fontFamily':'serif', 'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 28.06pt', 'paddingLeft':'7.94pt', 'textIndent':'0pt'}}>
                <span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>we receive court orders, subpoenas or other requests
                    for information by law enforcement;</span></li>
            <li
                style={{'fontFamily':'serif', 'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 28.06pt', 'paddingLeft':'7.94pt', 'textIndent':'0pt'}}>
                <span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>if otherwise permitted or required by law; or</span>
            </li>
            <li
                style={{'fontFamily':'serif', 'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt 28.06pt', 'paddingLeft':'7.94pt', 'textIndent':'0pt'}}>
                <span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>for other purposes with your consent.</span></li>
        </ul>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontStyle':'italic', 'textDecoration':'underline'}}>Sharing
                outside of Australia</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>We may disclose your personal information to a recipient
                located outside Australia. This may include the following:</span></p>
        <ul type="disc" style={{'margin':'0pt', 'paddingLeft':'0pt'}}>
            <li
                style={{'fontFamily':'serif', 'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 28.06pt', 'paddingLeft':'7.94pt', 'textIndent':'0pt'}}>
                <span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>third-party service providers operating overseas,
                    which are likely to be located in the United States;</span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> </span></li>
            <li
                style={{'fontFamily':'serif', 'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt 28.06pt', 'paddingLeft':'7.94pt', 'textIndent':'0pt'}}>
                <span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>for international transactions, such as currency
                    exchanges, we may need to disclose your information to the corresponding international party in
                    order to process the transaction. The countries we disclose your information to will depend on the
                    details of the transaction you ask us to carry out.</span></li>
        </ul>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>We may store your information in cloud or other types of
                networked or electronic storage. As electronic or networked storage can be accessed from various
                countries via an internet connection, it is not always practicable to know in which country your
                information may be accessed or held.  If your information is stored in this way, disclosures may occur
                in countries other than those listed.</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontWeight':'bold'}}>How do we store your personal
                information?</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>We take reasonable steps to protect your personal
                information from misuse, interference and loss, and from unauthorised access, modification or
                disclosure.</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Some of the ways we do this are:</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 36pt', 'textIndent':'-18pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>•</span><span
                style={{'font':'7.0pt Times New Roman'}}>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>security measures to control access to our systems and
                premises;</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 36pt', 'textIndent':'-18pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>•</span><span
                style={{'font':'7.0pt Times New Roman'}}>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>confidentiality obligations for employees and contractors
                who have access to your personal information;</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 36pt', 'textIndent':'-18pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>•</span><span
                style={{'font':'7.0pt Times New Roman'}}>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>limiting access to personal information to a person who is
                verified to be able to receive that information;</span></p>
        <p style={{'fontSize':'11pt','lineHeight':'108%', 'margin':'0pt 0pt 8pt 36pt', 'textIndent':'-18pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>•</span><span
                style={{'font':'7.0pt Times New Roman'}}>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>electronic security systems, such as firewalls and data
                encryption on our websites.</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>We can store personal information physically or
                electronically with third party data storage providers.  Where we do this, we use contractual
                arrangements to ensure those providers take appropriate measures to protect that information and
                restrict the uses to which they can put that information.</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontWeight':'bold'}}>Use of cookies</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontStyle':'italic', 'textDecoration':'underline'}}>What are
                cookies?</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Cookies are small text files that are used to store small
                pieces of information. The cookies are stored on your device when the </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Website </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>is loaded on your browser.</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Each cookie is unique to your web browser. It will contain
                some anonymous information such as a unique identifier, the </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>particular </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>website’s domain name, and some digits and numbers.</span>
        </p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontStyle':'italic', 'textDecoration':'underline'}}>What do we use
                cookies for?</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>We use cookies to:</span></p>
        <ul type="disc" style={{'margin':'0pt', 'paddingLeft':'0pt'}}>
            <li
                style={{'fontFamily':'serif', 'fontSize':'11pt', 'lineHeight':'107%', 'margin':'0pt 0pt 0pt 28.06pt', 'paddingLeft':'7.94pt', 'textIndent':'0pt'}}>
                <span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>support the functioning of our </span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Website</span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>;</span></li>
            <li
                style={{'fontFamily':'serif', 'fontSize':'11pt', 'lineHeight':'107%', 'margin':'0pt 0pt 0pt 28.06pt', 'paddingLeft':'7.94pt', 'textIndent':'0pt'}}>
                <span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>enhance your experience on our </span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Website </span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>by remembering your preferences;</span></li>
            <li
                style={{'fontFamily':'serif', 'fontSize':'11pt', 'lineHeight':'107%', 'margin':'0pt 0pt 0pt 28.06pt', 'paddingLeft':'7.94pt', 'textIndent':'0pt'}}>
                <span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>track how users use our </span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Web</span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>site;</span></li>
            <li
                style={{'fontFamily':'serif', 'fontSize':'11pt', 'lineHeight':'107%', 'margin':'0pt 0pt 0pt 28.06pt', 'paddingLeft':'7.94pt', 'textIndent':'0pt'}}>
                <span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>understand how our </span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Website</span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> </span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>is</span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> functioning and inform improvements to our </span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Website</span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> </span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>and </span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>App</span><span
                    style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>; and</span></li>
            <li
                style={{'fontFamily':'serif', 'fontSize':'11pt', 'lineHeight':'107%', 'margin':'0pt 0pt 8pt 28.06pt', 'paddingLeft':'7.94pt', 'textIndent':'0pt'}}>
                <span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>gather data that helps us deliver advertising content
                    relevant to your interests.</span></li>
        </ul>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontStyle':'italic', 'textDecoration':'underline'}}>What types of
                cookies and similar technologies do we use?</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>There are several different types of cookies that may be
                used on our websites.</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 36pt', 'textIndent':'-18pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>•</span><span
                style={{'font':'7.0pt Times New Roman'}}>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Service critical cookies, which are essential in order for
                you to visit and use the services on our </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Website</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>.</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 36pt', 'textIndent':'-18pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>•</span><span
                style={{'font':'7.0pt Times New Roman'}}>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Session cookies, which are deleted after each visit.</span>
        </p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 36pt', 'textIndent':'-18pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>•</span><span
                style={{'font':'7.0pt Times New Roman'}}>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Persistent cookies, which are valid across visits to our
            </span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Website</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>.</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 36pt', 'textIndent':'-18pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>•</span><span
                style={{'font':'7.0pt Times New Roman'}}>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Email cookies, which are set on your computer by an email
                from us.</span></p>
        <p style={{'fontSize':'11pt','lineHeight':'108%', 'margin':'0pt 0pt 8pt 36pt', 'textIndent':'-18pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>•</span><span
                style={{'font':'7.0pt Times New Roman'}}>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Third Party cookies, which are used by our trusted
                partners.</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>We also use analytics tools on our </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Website </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>and third-party websites to generate detailed statistics
                about traffic to our </span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Website</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>, the source of that traffic and how you interact with
                advertisements on our </span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Website </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>and third-party websites. </span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>We sometimes allow our trusted partners to use cookies or
                similar technologies on our </span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Website
            </span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>for the purpose of analytics and targeted online
                advertising.  Our trusted partners use cookies to collect data and allow them to recognize your computer
                or device when you visit our websites.  The data they collect is kept separate from the personal
                information about you that we collect. We do not access or control our trusted partner’s cookies.  It is
                our trusted partner’s privacy and cookie policies that apply to their use of these cookies and
                technologies.  </span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontStyle':'italic', 'textDecoration':'underline'}}>How do you
                disable cookies?</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>If you want to disable cookies that are set by our
            </span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Website</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>, you can do so through your browser settings.  Further
                details on how to disable cookies can be found at www.internetcookies.org.</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Because cookies are used throughout our </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Website</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>, disabling them may prevent you from using certain parts of
                the </span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Website</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>.</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontWeight':'bold'}}>How to contact us</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>If you have any questions about this </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Privacy </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Policy, </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>a complaint about the way we handle your</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}> personal </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>information</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>, or you would like to exercise one of your rights, please
                do not hesitate to contact us.</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Email us at:</span><span
                style={{'width':'20.57pt', 'textIndent':'0pt', 'display':'inline-block'}}></span><span
                style={{'width':'36pt', 'textIndent':'0pt', 'display':'inline-block'}}></span><a style={{'color':'#0563c1'}}
                href="mailto:safiesocial@gmail.com"><span
                    style={{'color':'#0563c1', 'fontFamily':'Calibri', 'fontSize':'11pt', 'textDecoration':'underline'}}>safiesocial@gmail.com</span></a>
        </p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Or write to us at:</span><span
                style={{'width':'32.09pt', 'textIndent':'0pt', 'display':'inline-block'}}></span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>PO Box 474, Cranbourne, VIC 3977.</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>We are committed to resolving your complaint and doing the
                right thing by our customers. Most complaints are resolved quickly, and you should hear from us within
                ten business days. </span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>If you still feel your issue has not been resolved to your
                satisfaction, then you can raise your concern with the Office of the Australian Information
                Commissioner:</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 36pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Online: </span><span
                style={{'width':'1.21pt', 'textIndent':'0pt', 'display':'inline-block'}}></span><span
                style={{'width':'36pt', 'textIndent':'0pt', 'display':'inline-block'}}></span><a style={{'color':'#0563c1'}}
                href="http://www.oaic.gov.au/privacy"><span
                    style={{'color':'#0563c1', 'fontFamily':'Calibri', 'fontSize':'11pt', 'textDecoration':'underline'}}>www.oaic.gov.au/privacy</span></a>
        </p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 36pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Phone: </span><span
                style={{'width':'2.05pt', 'textIndent':'0pt', 'display':'inline-block'}}></span><span
                style={{'width':'36pt', 'textIndent':'0pt', 'display':'inline-block'}}></span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>1300 363 992</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 36pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Email:</span><span
                style={{'width':'8.58pt', 'textIndent':'0pt', 'display':'inline-block'}}></span><span
                style={{'width':'36pt', 'textIndent':'0pt', 'display':'inline-block'}}></span><a style={{'color':'#0563c1'}}
                href="mailto:enquiries@oaic.gov.au"><span
                    style={{'color':'#0563c1', 'fontFamily':'Calibri', 'fontSize':'11pt', 'textDecoration':'underline'}}>enquiries@oaic.gov.au</span></a>
        </p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 0pt 36pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Fax:</span><span
                style={{'width':'17.97pt', 'textIndent':'0pt', 'display':'inline-block'}}></span><span
                style={{'width':'36pt', 'textIndent':'0pt', 'display':'inline-block'}}></span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>+61 2 9284 9666</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt 0pt 8pt 36pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Mail:</span><span
                style={{'width':'13.33pt', 'textIndent':'0pt', 'display':'inline-block'}}></span><span
                style={{'width':'36pt', 'textIndent':'0pt', 'display':'inline-block'}}></span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>GPO Box 5218 Sydney NSW 2001</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontWeight':'bold'}}>Changes to this </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontWeight':'bold'}}>Privacy </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt', 'fontWeight':'bold'}}>Policy</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>&#xa0;</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt'}}><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>This
            </span><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Privacy </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Policy may change. </span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt'}}><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>&#xa0;</span></p>
        <p style={{'fontSize':'11pt', 'lineHeight':'108%', 'margin':'0pt'}}><span style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>We
                will let you know of any changes to this </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Privacy </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Policy by posting a notification on our </span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>Website</span><span
                style={{'fontFamily':'Calibri', 'fontSize':'11pt'}}>, correspondence via post or e-mail or you may contact us
                for a copy of the most up to date policy at any time.</span></p>
    </div>
    <div class="cnzz" style={{'display': 'none'}}>
        <script src="https://s23.cnzz.com/z_stat.php?id=1277655852&web_id=1277655852" language="JavaScript"></script>
    </div>
           </div>
        )
    }
}

export default Term